/** @jsxImportSource @emotion/react */
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts'
import * as s from './style'
import React, { useEffect, useState } from 'react';

const SummaryGraph = ({ data, selectedCategory }) => {
    const [ options, setOptions ] = useState({
        title: { text: "" },
        chart: {
          backgroundColor: "rgba(0, 0, 0, 0)",
          height: 220,
          type: "column",
          style: { fontFamily: "SCDream4" },
        },
        xAxis: {
          labels: { style: { fontSize: "14px", fontWeight: 400, color: "#002755" } },
        },
        yAxis: { labels: { enabled: false }, title: { text: "" } },
        legend: { itemStyle: { fontSize: "13px", fontWeight: 700 } },
        credits: { enabled: false },
        series: [],
        accessibility: { enabled: false }
    });

    useEffect(() => {
        if(selectedCategory) {
            Highcharts.setOptions({ lang : {thousandsSep:','} });
            const tooltip = {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}:&nbsp </td>' +
                '<td style="padding:0"><b>{point.y:,f} ' + selectedCategory.unit + '</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            }

            setOptions((prevOptions) => ({
                ...prevOptions,
                tooltip: tooltip
            }))
        }
    }, [selectedCategory])


    useEffect(() => {
        setAllRegionData();
        Highcharts.setOptions({ lang : {thousandsSep:','} });
    }, [data])


    const setAllRegionData = () => {
        if(data && selectedCategory) {
            const yearArray = Array.from({ length: data.length}, (_, index) => (parseInt(selectedCategory.start_yyyy) + index));

            const allRegionDataArray = yearArray.map((year) => {
                const result =  {
                    x: year,
                    y: data.find(item => item.year === year.toString()).val
                }
                
                return result;
            })

            const allRegionSeries = {
                name: "전국",
                color : "#002755",
                data: allRegionDataArray
            }          

            setOptions((prevOptions) => ({
                ...prevOptions,
                series: [allRegionSeries]
            }))

        }
    }

    if(options)
    return (
        <div css={s.mainContainer}>
            <HighchartsReact highcharts={Highcharts} options={options}/>
        </div>
    );
};

export default SummaryGraph;