import axios from "axios";
import GalleryBoard from "../gallery_board/GalleryBoard";
import GeneralBoard from "../general_board/GeneralBoard";
import WebzineBoard from "../webzine_board/WebzineBoard";
import WebzineBoardScroll from "../webzine_board_scroll/WebzineBoardScroll";
import { API_URL } from "../../../config/URL/URL";

export const getBoardComponent = (type) => {
    switch (type) {
        case 1:
            return GeneralBoard;
        case 2:
            return WebzineBoard;
        case 3:
            return GalleryBoard;
        case 4:
            return WebzineBoardScroll;
        default:
            return null;
    }
};

export const getPosts = async (boardId, page, searchOption, count) => {
    const option = {
        params: {
            boardId: boardId,
            page: page,
            count: count,
            searchType: searchOption.type,
            searchValue: searchOption.value
        }
    }

    try {
        const response = await axios.get(`${API_URL}/board/getposts`, option);
        return response.data;
    } catch(error) {
        console.log(error);
    }
}

export const getPageSize = async (boardId, searchOption, count) => {
    const option = {
        params: {
            boardId: boardId,
            count: count,
            searchType: searchOption.type,
            searchValue: searchOption.value
        }
    }

    try {
        const response = await axios.get(`${API_URL}/board/getpage`, option);
        return response.data;
    } catch(error) {
        console.log(error)
    }
}