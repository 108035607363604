import { css } from "@emotion/react";

export const mainContainer = css`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px 0px;
`

export const boardContainer = css`
    display: flex;
    flex-direction: column;
    width: 1100px;
    padding: 10px 10px 10px 20px;
`

export const topMenu = css`
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 10px;
`

export const topMenuItem = css`
    display: flex;
    width: 400px;
    height: 300px;
    margin: 10px;
    border-radius: 5px;
    background-color: #eee;
`

export const header = css`
    display: flex;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid #dbdbdb;
    font-size: 20px;
`

export const postButtonContainer = css`
    display: flex;
    padding-top: 30px;
    justify-content: flex-end;
`

export const postButton = css`
    display: flex;
    width: 100px;
    height: 40px;
    align-items: center;
    justify-content: center;
    background-color: #3671c8;
    border: none;
    color: #fff;
    cursor: pointer;
`
