import axios from "axios";
import { API_URL } from "../../config/URL/URL";

export const createLog = async ({ page, eventName, eventValue, sessionToken, properties }) => {
    const data = {
      page: page,
      eventName: eventName,
      eventValue: eventValue,
      properties: JSON.stringify(properties)
    };

    const option = {
      headers: {
        sessionToken: sessionToken,
        "Content-Type": "application/json"
      }
    }
  
    try {
      const response = await axios.post(`${API_URL}/log/create`, JSON.stringify(data), option);
      const token = `Bearer ${response.data}`
      return token;
  
    } catch (error) {
      console.log(error);
    }
  }