/** @jsxImportSource @emotion/react */
import * as s from './style'
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

const SearchBar = ({ selectedBoard, searchOption }) => {
    const navigate = useNavigate();
    const [ searchType, setSearchType ] = useState("title");
    const [ searchInput, setSearchInput ] = useState("");

    useEffect(() => {
        setSearchType(searchOption.type);
        setSearchInput(searchOption.value);
    }, [searchOption])

    const onChangeSearchInputHandle = (e) => {
        setSearchInput(e.target.value);
    }

    const onChangeSelectHandle = (e) => {
        setSearchType(e.target.value);
    }

    const onClickSearchButtonHandle = () => {
        search();
    }

    const search = () => {
        const searchOption = {
            type: searchType,
            value: searchInput
        }
        navigate(`/test/board/${selectedBoard.id}`, {state: {searchOption: searchOption}});
    }

    const onEnterKeyDownHandle = (e) => {
        if (e.key === 'Enter') {
            search();
        }
    }

    return (
        <div css={s.searchBarContainer}>
            <select css={s.typeList} onChange={onChangeSelectHandle} value={searchType}>
                <option value="title">제목</option>
                <option value="content">내용</option>
                <option value="all">제목+내용</option>
            </select>
            <input css={s.searchInput} name='searchInput' value={searchInput} onChange={onChangeSearchInputHandle} onKeyDown={onEnterKeyDownHandle}/>
            <button css={s.searchButton} onClick={onClickSearchButtonHandle}><SearchIcon/></button>
        </div>
    );
};

export default SearchBar;