/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

const button = (theme) => css`
    height: 38px;
    font-weight: 700;
    border: none;
    border-radius: 6px;
    ${theme === "green" ? "background-color: rgba(3, 199, 90, 0.12);" : theme === "gray" ? "background-color: #eff0f2);" : ""}
    ${theme === "green" ? "color: #009f47;" : theme === "gray" ? "color: #000000;" : ""}
    padding: 10px;
    cursor: pointer;
    text-align: center;
`

const Button = ({ children, onClick, theme, style }) => {

    return (
        <button css={button(theme)} style={style} onClick={onClick}>
            {children}
        </button>
    );
};

export default Button;