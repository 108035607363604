import { css } from "@emotion/react";

export const mainContainer = css`
    flex-wrap: nowrap;
    display: flex;
    justify-content: center;
`
export const header = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    margin: 0px auto;

    font-size: 18px;
`

export const tableContainer = css`
    width: 480px;
    padding: 1rem;
    min-height: 100vh;
`

export const yearSelectorContainer = css`
    display: inline-flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    align-items: center;
`

export const categoryName =css`
    font-size: 13px;
    font-weight: 700;
`

export const modalOpenButton = css`
    display: flex;
    align-items: center;
    color:#fff;
    background-color:#5bc0de;
    border-color:#46b8da;
    background-color: rgb(0, 39, 85);
    border-color: rgb(0, 39, 85);
    font-size: 10px;
    float: right;
    height: 25.5px;
    cursor: pointer;
`

export const tableIcon = css`
    font-size: 13px;
`

export const mapContainer = css`
    width: 700px;
    height: 890px;
    padding: 1rem;
`

export const map = css`
    position: absolute;
    width: inherit;
    height: inherit;
    float: left;
`

