/** @jsxImportSource @emotion/react */
import ImageInput from './image_input/ImageInput';
import * as s from './style'
import React, { useState } from 'react';

const ThumbUploadComponent = ({ imageList, thumb, setThumb }) => {
    
    const onClickItemHandle = (item, idx) => {
        setThumb({
            index: idx,
            image: item
        });
    }

    const setSelectedImage = (image) => {
        setThumb({
            index: null,
            image: image
        })
    }

    return (
        <ul css={s.mainContainer}>
            <li css={s.item}>
                <ImageInput selectedImage={thumb.image} setSelectedImage={setSelectedImage} />
            </li>
            {imageList.map((image, idx) => (
                <li key={idx} css={[s.item, s.selected(thumb.index === idx)]} onClick={() => onClickItemHandle(image, idx)}>
                    <img src={image} alt={`image_${idx}`} css={s.image}/>
                </li>
            ))}

        </ul>
    );
};

export default ThumbUploadComponent;