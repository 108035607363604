/** @jsxImportSource @emotion/react */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { API_URL } from '../../../config/URL/URL';
import * as s from "./style"

const EmbedButton = ({ selectedCategory, selectedYear, selectedSido, selectedSgg, view, map }) => {
    const copyRef = useRef();
    const [ isOpen, setIsOpen ] = useState(false);
    const [ isModalOpen, setIsModalOpen] = useState(false);

    const [ url, setUrl ] = useState("");

    const modalRef = useRef();

    const [ size, setSize ] = useState({
        width: 700,
        height: 500,
    })

    const [ mapImgUrl, setMapImgUrl ] = useState()

    const createEmbed = async () => {
        const option = {
            headers: {
                "Content-Type": "application/json"
            }
        }

        const properties = {
            selectedCategory: selectedCategory,
            selectedYear: selectedYear,
            selectedSido: selectedSido,
            selectedSgg: selectedSgg,
            view: {
                center: view.getCenter(),
                zoom: view.getZoom()
                
            }
        }

        const data = {
            properties: JSON.stringify(properties)
        }

        try {
            const response = await axios.post(`${API_URL}/embed/create`, data, option);
            return response.data;

        } catch (error) {
            console.log(error)
        }
    }

    const onClickButtonHandle = () => {
        createEmbed();
        setIsOpen(!isOpen);
    }

    const onClickCreateButtonHandle = async () => {
        const code = await createEmbed();
        setUrl(`<embed src=https://ypt.kr:7005/${code} width="${size.width}px" height="${size.height}px" />`)
    }

    const onClickCopyButtonHandle = () => {
        navigator.clipboard.writeText(copyRef.current.innerText)
            .then(() => {
                alert("코드가 클립보드에 복사되었습니다.");
            })
            .catch((error) => {
                alert("클립보드 복사에 실패하였습니다.")
            })
    }

    const onChangeInputHandle = (e) => {
        const { name, value } = e.target;
        setSize({...size, [name]: Number(value)});
    }

    const onClickModalOpenButton = () => {
        setIsModalOpen(true)

        const mapCanvas = document.createElement('canvas')
        mapCanvas.width = size.width
        mapCanvas.height = size.height

        const mapContext = mapCanvas.getContext('2d')

        Array.prototype.forEach.call(
            map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
            (canvas) => {
                if (canvas.width > 0) {
                    const opacity = canvas.parentNode.style.opacity || canvas.style.opacity;

                    mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);

                    let matrix;
                    const transform = canvas.style.transform;

                    if (transform) {
                        matrix = transform
                            .match(/^matrix\(([^\(]*)\)$/)[1]
                            .split(",")
                            .map(Number);
                    } else {
                        matrix = [
                          parseFloat(canvas.style.width) / canvas.width,
                          0,
                          0,
                          parseFloat(canvas.style.height) / canvas.height,
                          0,
                          0,
                        ];
                    }

                    CanvasRenderingContext2D.prototype.setTransform.apply(
                        mapContext,
                        matrix,
                    );

                    const backgroundColor = canvas.parentNode.style.backgroundColor;
                    if (backgroundColor) {
                      mapContext.fillStyle = backgroundColor;
                      mapContext.fillRect(0, 0, canvas.width, canvas.height);
                    }
                    mapContext.drawImage(canvas, 0, 0);
                }
            } 
        )

        mapContext.globalAlpha = 1;
        mapContext.setTransform(1, 0, 0, 1, 0, 0);

        const imgUrl = mapCanvas.toDataURL('image/png');
        setMapImgUrl(imgUrl)
    }

    const onClickModalCloseButton = () => {
        setIsModalOpen(false)
    }

    const onClickModalOutsideHandle = (e) => {
        if(modalRef.current && !modalRef.current.contains(e.target)) {
            setIsModalOpen(false)
        }
    }

    return (
        <div css={s.mainContainer}>
            <button onClick={onClickButtonHandle}>
                내보내기
            </button>
            <div css={s.embedContainer(isOpen)}>
                <div css={s.copyUrlContainer}>
                    <header css={s.header}>
                        지도 공유
                    </header>
                    <div css={s.paramContainer}>
                        <div>
                            <span>width: </span>
                            <input css={s.input} name={"width"} type="number" min={100} max={1000} value={size.width} onChange={onChangeInputHandle}/>
                            <span>px</span>
                        </div>
                        <div>
                            <span>height: </span>
                            <input css={s.input} name={"height"} type="number" min={100} max={800} value={size.height} onChange={onChangeInputHandle}/>
                            <span>px</span>
                        </div>
                        <button onClick={onClickModalOpenButton}>미리보기</button>
                        <button css={s.createButton} onClick={onClickCreateButtonHandle}>생성</button>
                    </div>
                    <div css={s.urlContainer}>
                        <p css={s.urlText} ref={copyRef}>{url}</p>
                    </div>
                    <button css={s.copyButton} onClick={onClickCopyButtonHandle}>복사</button>
                </div>
                <div css={s.modalContainer(isModalOpen)} onClick={onClickModalOutsideHandle}>
                    <div css={s.modalContent} ref={modalRef}>
                        <header css={s.modalHeader}>
                            <span css={s.modalHeaderText}>미리보기</span>
                            <button css={s.modalCloseButton} onClick={onClickModalCloseButton}>X</button>
                        </header>
                        <div css={s.imgContainer(size)}>
                            <img css={s.img} src={mapImgUrl} width={size.width} height={size.height} alt='미리보기'/>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmbedButton;