/** @jsxImportSource @emotion/react */

import { useLocation, useNavigate } from 'react-router-dom';
import * as s from './style'
import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar } from '@mui/material';
import ImgToggleButton from '../atoms/button/ImgToggleButton';
import { useRecoilState } from 'recoil';
import { logTokenAtom } from '../../atoms/log/LogTokenStore';
import { createLog } from '../../atoms/log/LogFunction';

const Header = ({ currentPathName, isRoot }) => {
    const location = useLocation();
    const [ logTokenState, setLogTokenState ] = useRecoilState(logTokenAtom);

    const navigate = useNavigate();
    const [ logoSrc, setLogoSrc ] = useState("/images/sodamap_logo1.png");

    useEffect(() => {
        if(isRoot) {
            setLogoSrc("/images/sodamap_logo1.png");
        } else {
            setLogoSrc("/images/sodamap_logo2.png");
        }
    }, [isRoot])

    const onClickLogoHandle = async () => {
        navigate("/");

        const logOption = {
            page: location.pathname,
            eventName: "navigate_home",
            eventValue: null,
            sessionToken: logTokenState,
          };
      
          const newToken = await createLog(logOption);
          setLogTokenState(newToken);
    }

    const onClickMailButtonHandle = async () => {
        window.open("mailto:info@ypt.kr;info@plqpartners.com", "_blank");

        const logOption = {
            page: location.pathname,
            eventName: "open_mail",
            eventValue: null,
            sessionToken: logTokenState,
          };
      
          const newToken = await createLog(logOption);
          setLogTokenState(newToken);
    }

    const onClickInstagramButtonHandle = async () => {
        window.open("https://www.instagram.com/y_partners_/?utm_medium=copy_link", "_blank");

        const logOption = {
            page: location.pathname,
            eventName: "open_instagram",
            eventValue: null,
            sessionToken: logTokenState,
          };
      
          const newToken = await createLog(logOption);
          setLogTokenState(newToken);
    }

    const onClickGalleryButtonHandle = async () => {
        navigate("/gallery", { replace: true });

        
        const logOption = {
            page: location.pathname,
            eventName: "navigate_gallery",
            eventValue: null,
            sessionToken: logTokenState,
          };
      
          const newToken = await createLog(logOption);
          setLogTokenState(newToken);
    }

    const onClickSodamapButtonHandle = async () => {
        navigate("/sodamap", { replace: true });

        
        const logOption = {
            page: location.pathname,
            eventName: "navigate_sodamap",
            eventValue: null,
            sessionToken: logTokenState,
          };
      
          const newToken = await createLog(logOption);
          setLogTokenState(newToken);
    }

    return (
        <AppBar css={s.mainContainer(isRoot)} position="static" elevation={0}>
            <Toolbar css={s.toolbar(isRoot)}>
                <div css={s.logo} onClick={onClickLogoHandle}>
                    <img css={s.logoImg} src={logoSrc} alt='logo' />
                    <span css={s.subText(isRoot)}>Social Data Map</span>
                </div>
                <div css={s.navigateButtonContainer(isRoot)}>
                    <ImgToggleButton name="galleryButton" isToggled={currentPathName !== "/gallery"} hover={true} firstImgSource={"/images/btn-top_1.png"} secondImgSource={"/images/btn-top_1a.png"} onClick={onClickGalleryButtonHandle}/>
                    <ImgToggleButton name="sodamapButton" isToggled={currentPathName !== "/sodamap"} hover={true} firstImgSource={"/images/btn-top_2.png"} secondImgSource={"/images/btn-top_2a.png"} onClick={onClickSodamapButtonHandle}/>
                </div>
                <div css={s.socialLinksContainer}>
                    <ImgToggleButton name="mailButton" isToggled={isRoot} firstImgSource={"/images/icon_1.png"} secondImgSource={"/images/icon-a_1.png"} onClick={onClickMailButtonHandle}/>
                    <ImgToggleButton name="instagramButton" isToggled={isRoot} firstImgSource={"/images/icon_2.png"} secondImgSource={"/images/icon-a_2.png"} onClick={onClickInstagramButtonHandle}/>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Header;