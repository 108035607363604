/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

const buttonContainer = css`
    display: flex;
    align-items: center;
    color:#fff;
    background-color: #002755;
    border-color: #002755;
    padding: 0px 5px;
    font-size: 10px;
    height: 25.5px;
    cursor: pointer;
`

const tableIcon = css`
    margin-left: 5px;
    font-size: 13px;
`

const modalOpenButton = ({ children, icon: IconComponent, onClick }) => {
    return (
        <button css={buttonContainer} onClick={onClick}>
            <span>{children}</span>
            <IconComponent css={tableIcon}/>
        </button>
    );
};

export default modalOpenButton;