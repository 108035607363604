import { css } from "@emotion/react";

export const mainContainer = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    
    background-color: #FCFCFD;
    border: 1px solid #EAECF0;
`

export const header = css`
    font-size: 14px;
    border-bottom: 1px solid #EAECF0;
    padding: 8px;
`

export const headerText = css`
    margin-left: 5px;
`

export const file = css`
    font-size: 13px;
    border-bottom: 1px solid #EAECF0;
    padding: 10px 8px;
`

export const fileName = css`
    margin-left: 8px;
    cursor: pointer;
`

export const imageContainer = css`
    background-color: #fff;
    padding: 15px;
`

export const image = css`
    width: 100px;
    height: 100px;
    object-fit: cover;
`