import { css } from "@emotion/react";

export const mainContainer = css`
    display: flex;
    border: 2px solid lightgray;
    background-color: lightgray;
    margin: auto;
    height: 203px;
    justify-content: space-between;
    flex-direction: row;
`

export const col = css`
    display: flex;
    width: 33%;
    flex-direction: column;
`

export const header = css`
    display: flex;
    height: 25.5px;
    align-items: center;
    text-align: left;
    font-size: 17px;
    color: #002755;
    padding-left: 5px;
`

export const content = css`
    float: left;
    text-align: left;
    padding: 5px;
    height: 175px;
    overflow: auto;
    font-size: 13px;
    background-color: white;
    margin-left: 0.3%;
`

export const tipText = css`
    font-size: 11px;
`