import { css } from "@emotion/react"

export const searchBarContainer = css`
    display: flex;
    position: relative;
    padding: 50px 0px 10px 10px;
`

export const searchInput = (isNull)=>css`
    width: 300px;
    height: 40px;
    border: none;
    border-right: none;
    border-bottom: 1px solid #232324;
    background-color: #fff;
    color: ${isNull ? "#dbdbdb" : "#232324"};
    font-size: 18px;
    &:focus {
        outline: none;
    }
`

export const searchButton = css`
    position: absolute;
    right: -5px;
    bottom: 15px;
    color: #232324;
    border: none;
    background-color: transparent;
    cursor: pointer;
`
