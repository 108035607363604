/** @jsxImportSource @emotion/react */
import * as s from './style'
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';

const SearchBarScroll = ({ selectedBoard, searchOption, setSearchOption }) => {
    const searchType = "all"
    const [ searchInput, setSearchInput ] = useState("");

    useEffect(() => {
        setSearchInput(searchOption.value);
    }, [searchOption])

    const onChangeSearchInputHandle = (e) => {
        setSearchInput(e.target.value);
    }

    const onClickSearchButtonHandle = () => {
        search();
    }

    const search = () => {
        const searchOption = {
            type: searchType,
            value: searchInput
        }
        setSearchOption(searchOption);
    }

    const onEnterKeyDownHandle = (e) => {
        if (e.key === 'Enter') {
            search();
        }
    }

    return (
        <div css={s.searchBarContainer}>
            <input css={s.searchInput(searchInput === "")} name='searchInput' value={searchInput} onChange={onChangeSearchInputHandle} onKeyDown={onEnterKeyDownHandle} placeholder='검색어를 입력해주세요.'/>
            <button css={s.searchButton} onClick={onClickSearchButtonHandle}><SearchIcon/></button>
        </div>
    );
};

export default SearchBarScroll;