import { css } from "@emotion/react";

export const mainContainer = css`
    display: flex;
    background-color: lightgray;
    width: 100%;
    flex-direction: column;
`

export const header = css`
    display: flex;
    height: 25.5px;
    align-items: center;
    text-align: left;
    font-size: 15px;
    margin-left: 5px;
    padding-top: 10px;
`

export const content = css`
    display: flex;
    flex-direction: column;
    margin: 0px 5px 5px 5px;
    padding: 0px 15px;
    height: 200px;
    overflow: auto;
    font-size: 13px;
    background-color: white;
    border: 1px solid gray;
`

export const group = css`
`

export const item = (isSelected) => css`
    display: ${isSelected ? "flex" : "none"};
    flex-direction: column;
    margin-left: 10px;
`