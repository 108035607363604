import { css } from "@emotion/react"

export const mainContainer = css`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px 0px;
`

export const sidebarContainer = css`
    display: flex;
    width: 250px;
    min-height: 720px;
    flex-direction: row;
    
    border: 1px solid #dbdbdb;
    margin-right: -1px;
`

export const postContainer = css`
    display: flex;
    flex-direction: column;
    width: 1100px;
    padding: 25px 10px 10px 20px;
    background-color: #fff;
    
    border: 1px solid #dbdbdb;
`

export const header = css`
    display: flex;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid #dbdbdb;
    font-size: 20px;
    color: #555555;
`

export const main = css`
    display: flex;
    flex-direction: column;
    min-height: 600px;
    margin-top: 40px;
    background-color: #fff;
`

export const infoContainer = css`

`

export const title = css`
    font-size: 26px;
    font-weight: 700;
    line-height: 1.38;
    letter-spacing: -.6px;
    color: #000000;
    margin-bottom: 20px;
`

export const postInfo = css`
    display: flex;
    justify-content: flex-end;
`

export const content = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: -.5px;
    color: #444444;
    padding: 30px 0px;

    img {
        max-width: 100%;
        height: auto;
    }
`

export const commentContainer = css`
    padding-bottom: 20px;
`

export const attachContainer = css`
    padding-bottom: 20px;
    border-bottom: 1px solid #dbdbdb;
`

export const footerButtonContainer = css`
    display: flex;
    align-items: center;
`

