/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';

const icon = (size, coord, backgroundColor, isHover) => css`
    display: flex;
    position: absolute;
    width: ${size + "px"};
    height: ${size + "px"};
    background-color: ${backgroundColor};
    border-radius: 50%;
    margin-top: ${coord[0] + "px"};
    margin-left: ${coord[1] + "px"};

    cursor: ${isHover ? "pointer" : ""};


    &:hover {
        background-color: ${isHover ? "lightgray" : ""};
        opacity: ${isHover ? 0.8 : ""};
    }
`

const iconImg = (padding) => css`
    padding: ${padding + "px"};
`

const iconHoverText = css`
    display: flex;
    position: absolute;
    color: dimgray;
    width: 100%;
    height: 100%;
    font-size: 20px;
    font-weight: 700;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
`

const GalleryMenuIcon = ({ size, coord, backgroundColor, padding, src, hoverText, onClick }) => {
    const [ isHover, setIsHover ] = useState(false);

    const onMouseOverHandle = () => {
        if(hoverText) {
            setIsHover(true);
        }
    }

    const onMouseLeaveHandle = () => {
        if(hoverText){
            setIsHover(false);
        }
    }

    return (
        <div css={icon(size, coord, backgroundColor, isHover)} onMouseOver={onMouseOverHandle} onMouseLeave={onMouseLeaveHandle} onClick={onClick}>
            {src && <img css={iconImg(padding)} src={src} alt="icon-img"/>}
            {isHover && <div css={iconHoverText}>{hoverText}</div>}

        </div>
    );
};

export default GalleryMenuIcon;