import { css } from '@emotion/react';

export const mainContainer = (isRoot) => css`
    display: flex;
    align-items: center;
    height: 50px;
    background-color: ${isRoot ? '#fdfdfd' : '#002755'};
`

export const toolbar = (isRoot) => css`
    display: flex;
    width: 1400px;
    min-height: 100% !important;
    background-color: ${isRoot ? '#fdfdfd' : '#002755'};
    justify-content: space-between;
`

export const logo = css`
    display: inline-flex;
    flex: 1;
    align-items: center;
    transform: translate(0, 10%);
    cursor: pointer;
`

export const logoImg = css`
    width: 135px;
`

export const subText = (isRoot) => css`
    padding-left: 10px;
    color: ${isRoot ? '#777777' : '#fdfdfd'};
    font-size: 17px;
`

export const navigateButtonContainer = (isRoot) => css`
    display: flex;
    visibility: ${isRoot ? "hidden" : "visible"};
    flex: 1;
    justify-content: center;
`

export const socialLinksContainer = css`
    display: flex;
    justify-content: end;
    flex: 1;

    img {
        width: 25px;
        height: 25px;
    }
`