/** @jsxImportSource @emotion/react */
import { Global, css } from '@emotion/react';
import './App.css';
import { Reset } from './style/Reset';
import { Route, Routes, useLocation } from 'react-router-dom';
import Main from './page/main/Main';
import Sodamap from './page/sodamap/Sodamap';
import Gallery from './page/gallery/Gallery';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import { useEffect, useRef, useState } from 'react';
import SodamapTest from './page/sodamap_test/SodamapTest';
import Board from './page/board/Board';
import ViewPost from './page/board/view_post/ViewPost';
import CreatePost from './page/board/create_post/CreatePost';
import { useRecoilState } from 'recoil';
import { logTokenAtom, getToken } from './atoms/log/LogTokenStore';
import { createLog } from './atoms/log/LogFunction';
import Embed from './page/embed/Embed';

const documentContainer = (isRoot) => css`
  display: flex;
  flex-direction: column;
  min-width: 1430px;
  align-items: center;
  
  background-color: ${isRoot ? '#002755' : '#fdfdfd'};
  color: ${isRoot ? '#fdfdfd' : 'black'};
`

const mainContainer = css`
  display: flex;
  width: 1370px;
  justify-content: center;
`

function App() {
  const location = useLocation();
  const [ logTokenState, setLogTokenState ] = useRecoilState(logTokenAtom);

  const ref = useRef();
  const [ currentPathName, setCurrentPathName ] = useState(location.pathname);
  const [ isRoot, setIsRoot ] = useState(true);

  useEffect(() => {
    if(logTokenState === null && ref.current) {
      fetchTokenState();
    }
  }, [])


  const fetchTokenState = async () => {
    const token = await getToken();

    const logOption = {
      page: location.pathname,
      eventName: "visit",
      eventValue: null,
      sessionToken: token,
      properties: {
        referrer: document.referrer
      }
    };

    const newToken = await createLog(logOption);
    await viewPageLog(newToken);

  }

  const viewPageLog = async (token) => {
    const logOption = {
        page: location.pathname,
        eventName: "view_page",
        eventValue: null,
        sessionToken: token,
    }
    
    const newToken = await createLog(logOption);
    setLogTokenState(newToken);
}

  useEffect(() => {
    setCurrentPathName(location.pathname);
    setIsRoot(location.pathname === "/");
  }, [location.pathname])

  return (
      <div css={documentContainer(isRoot)} ref={ref}>
        <Global styles={Reset}></Global>
        <Header currentPathName={currentPathName} isRoot={isRoot}/>
        <main css={mainContainer}>  
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/sodamap" element={<Sodamap />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/test/map" element={<SodamapTest />} />
            {/* <Route path="/embed/:code" element={<Embed />} /> */}

            <Route path="/test/board" element={<Board />} />
            <Route path="/test/board/:boardIndex" element={<Board />} />
            <Route path="/test/board/:boardIndex/:postIndex" element={<ViewPost />} />
            <Route path="/test/board/writepost" element={<CreatePost />} />
            <Route path="/test/board/writepost/:boardIndex" element={<CreatePost />} />
          </Routes>
        </main>
        <Footer currentPathName={currentPathName} isRoot={isRoot}/>
      </div>
  );
}

export default App;
