/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import * as s from './style'
import CategoryMenu from '../../components/gallery/category_menu/CategoryMenu';
import SummaryGraph from '../../components/gallery/summary_graph/SummaryGraph';
import axios from 'axios';
import { API_URL } from '../../config/URL/URL';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { logTokenAtom } from '../../atoms/log/LogTokenStore';
import { createLog } from '../../atoms/log/LogFunction';

const Gallery = () => {
    const location = useLocation();
    const [ logTokenState, setLogTokenState ] = useRecoilState(logTokenAtom);
    const ref = useRef();

    const [ data, setData ] = useState();
    const [ selectedCategory, setSelectedCategory ] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if(selectedCategory){
            fetchData();
        }
    }, [selectedCategory])

    const fetchData = async () => {
        const allRegionData = await getAllRegionData();
        setData(allRegionData);
    }

    const getAllRegionData = async () => {
        const option = {
            params: {
                category_cd: selectedCategory.categoryCd
            }
        }

        const response = await axios.get(`${API_URL}/sodamap/category/data/allregion`, option);

        return response.data;
    }

    const onClickMapLinkButtonHandle = async () => {
        navigate("/sodamap");

        const logOption = {
            page: location.pathname,
            eventName: "click_maplink_button",
            eventValue: null,
            sessionToken: logTokenState,
        }
        
        const newToken = await createLog(logOption);
        setLogTokenState(newToken);
    }

    return (
        <div css={s.container} ref={ref}>
            <div css={s.mapContainer}>
                {selectedCategory ? <img css={s.mapImg} src={`gif/${selectedCategory.nm_en}.gif`} alt="map"/> : ""}
            </div>
            <div css={s.tableContainer}>
                <CategoryMenu selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}/>

                <div css={s.textDataContainer}>
                    <header css={s.header}>{selectedCategory ? selectedCategory.nm_kor : ""}</header>
                    <p css={s.content}>{selectedCategory ? selectedCategory.description : ""}</p>
                </div>

                <SummaryGraph data={data} selectedCategory={selectedCategory}/>

                <div css={s.mapLinkContainer}>
                    <p css={s.mapLinkText}>
                        <span css={s.mapLinkCategoryName}>{selectedCategory ? selectedCategory.nm_kor : ""}</span>
                        <span>{` 지역별 데이터를 SODAMAP에서 직접 확인할 수 있어요.`}</span>
                    </p>
                    <button css={s.mapLinkButton} onClick={onClickMapLinkButtonHandle}>
                        <span>SODAMAP 상세보기</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Gallery;