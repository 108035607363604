import { css } from "@emotion/react";

export const mainContainer = css`
    display: inline-flex;
    border-radius: 2.5px;
    color: #212529;
    font-size: 10px;
`

export const arrow = (display) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    font-size: 15px;
    cursor: ${display ? "pointer" : "default"};
`

export const item = (display, selected) => css`
    display: ${display ? "flex" : "none"};
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    font-weight: 400;
    cursor: pointer;
    
    border: 1px solid ${selected ? "#002755" : "#dbdbdb"};
    color: ${selected ? "#fff" : "#212529"};
    background-color: ${selected ? "#002755" : ""};
`

export const disPlayAllItem = (displyAll) => css`
    font-size: ${displyAll ? "12px" : ""};
    
    padding: 7px 15px;
`
