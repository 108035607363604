import { css } from "@emotion/react";

export const mainContainer = css`
    width: 124px;
    height: 25px;
    font-weight: 400;
    font-size: 15px;
    float: left;
`

export const option = css`
`