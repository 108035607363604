import { css } from "@emotion/react";

export const mainContainer = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    
    padding: 10px 0px;
    border-bottom: 1px solid #dbdbdb;
`

export const header = css`
    font-size: 13px;
    font-weight: 700;
    padding-bottom: 5px;
`

export const contentText = css`
    font-size: 13px;
    font-weight: 400;
    line-height: 150%;
    padding-bottom: 8px;
`

export const footer = css`
    font-size: 11px;
    color: #979797;
`
