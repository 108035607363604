/** @jsxImportSource @emotion/react */
import { useLocation } from 'react-router-dom';
import * as s from './style'
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { logTokenAtom } from '../../../atoms/log/LogTokenStore';
import { createLog } from '../../../atoms/log/LogFunction';

const AllSggTable = ({ data, selectedYear, selectedCategory, selectedSgg, setSelectedSgg, setFlag }) => {
    const location = useLocation();
    const [ logTokenState, setLogTokenState ] = useRecoilState(logTokenAtom);

    const [ years, setYears ] = useState();
    const [ tableData, setTableData ] = useState();
    const [ sortCriteria, setSortCriteria ] = useState();
    const tableRef = useRef();
    
    useEffect(() => {
        if(data) {
            setInitTable();
        }
    }, [data])

    useEffect(() => {
        if(sortCriteria) {
            setTableDataRanked(sortCriteria);
        }
    }, [sortCriteria])

    useEffect(() => {
        if (tableRef.current) {
            moveScrollToSelectedSgg();
        }
    }, [tableRef.current, selectedSgg]);

    const setInitTable = async () => {
        const { start_yyyy, end_yyyy } = selectedCategory;
        const yearArray = Array.from({ length: parseInt(end_yyyy) - parseInt(start_yyyy) + 1}, (_, index) => (parseInt(selectedCategory.start_yyyy) + index));
        const criteria = { sortBy: Math.max(...yearArray), order: "descending" }
        setTableDataRanked(criteria);

        setSortCriteria(criteria);
        setYears(yearArray);
    }
    

    const setTableDataRanked = (criteria) => {
        if(data) {
            const filteredData = data.filter(item => item.adminCd.trim() === "39" || item.adminCd.trim() === "29" || item.adminCd.trim().length >= 5);
            const yearArray = Array.from(new Set(filteredData.map(item => item.year)));
    
            const codeSet = new Set(filteredData.map(item => JSON.stringify({ adminCd: item.adminCd, adminNm: item.adminNm })));
            const codes = Array.from(codeSet).map(code => JSON.parse(code));
    
            const resultArray = codes.map(item => {
                const resultItem = {
                    adminCd: item.adminCd,
                    adminNm: item.adminNm
                }

                
                yearArray.forEach(year => {
                    const findItem = filteredData.find(data => data.year === year && data.adminCd === item.adminCd)

                    if(findItem) {
                        resultItem[year] = findItem.val
                    } else {
                        resultItem[year] = "데이터 없음"
                    }
                })
    
                return resultItem;
            })
    
            const sortedArray = [...resultArray].sort((a, b) => {
                if(criteria.order === "descending") {
                    return b[criteria.sortBy] - a[criteria.sortBy];
                } else if(criteria.order === "ascending") {
                    return a[criteria.sortBy] - b[criteria.sortBy];
                } else {
                    return 0;
                }
            })

            sortedArray.forEach((item, idx) => {
                if(criteria.order === "descending") {
                    item.rank = idx + 1;
                } else if(criteria.order === "ascending") {
                    item.rank = sortedArray.length - idx;
                }
            })    
            setTableData(sortedArray)
        }
    }

    const onClickCloseHandle = async () => {
        setFlag(false);

        const logOption = {
            page: location.pathname,
            eventName: "select_sgg_ranktable",
            eventValue: null,
            sessionToken: logTokenState,
            properties: {
                year: selectedYear,
                categoryCd: selectedCategory.categoryCd,
                action: "close"
            }
          };
      
          const newToken = await createLog(logOption);
          setLogTokenState(newToken);
    }


    const onClickYearHandle = (year) => {
        if(sortCriteria.sortBy === year) {
            if(sortCriteria.order === "descending") {
                setSortCriteria({ sortBy: year, order: "ascending"})
            } else if (sortCriteria.order === "ascending") {
                setSortCriteria({ sortBy: year, order: "descending"})
            }
        } else {
            setSortCriteria({ sortBy: year, order: "descending"})
        }
    }

    const onClickRowHandle = async (data) => {
        if(data.adminCd !== selectedSgg.adminCd) {
            setSelectedSgg({
              adminCd: data.adminCd,
              adminNm: data.adminNm
            });

            const logOption = {
                page: location.pathname,
                eventName: "select_sgg_allregiontable",
                eventValue: null,
                sessionToken: logTokenState,
                properties: {
                    year: selectedYear,
                    categoryCd: selectedCategory.categoryCd,
                    adminCd: data.adminCd,
                    adminNm: data.adminNm
                }
              };
          
              const newToken = await createLog(logOption);
              setLogTokenState(newToken);

          } else if(data.adminCd === selectedSgg.adminCd) {
            setSelectedSgg({
              adminCd: "",
              adminNm: "",
            })
          }
    }

    const moveScrollToSelectedSgg = () => {
        const selectedElement = Array.from(tableRef.current.querySelectorAll('*')).find(
            (element) => element.textContent === selectedSgg.adminNm
        );

        if (selectedElement) {
            tableRef.current.scrollTop = selectedElement.offsetTop;
        }
    }

    if(years && tableData)
    return (
        <div css={s.mainContainer} ref={tableRef}>
            <div css={s.closeContainer}>
                <span css={s.closeButton} onClick={onClickCloseHandle}>x</span>
            </div>
            <table css={s.table}>
                <thead css={s.thead}>
                    <tr>
                        <th css={s.thAndTd}>순위</th>
                        <th css={s.thAndTd}>지역</th>
                        {years.map((year, idx) => (
                            <th key={idx} css={s.thAndTd}>
                                <div css={s.year} onClick={() => onClickYearHandle(year)}>
                                    <span>{year}</span>
                                    <span>
                                        {year !== sortCriteria.sortBy
                                                    ? ""
                                                    : sortCriteria.order === "descending" ? "↓" 
                                                    : "↑"}
                                    </span>
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody css={s.tbody}>
                    {tableData.map((item, idx) => (
                        <tr css={s.tr(item.adminCd.trim() === selectedSgg.adminCd.trim())} key={idx} onClick={() => onClickRowHandle(item)}>
                            <th css={s.thAndTd}>
                                <b css={s.rankText}>{item.rank}</b>
                            </th>
                            <th css={s.thAndTd}>
                                <p css={s.tableText}>{item.adminNm}</p>
                            </th>
                            {years.map((year, yearIdx) => (
                                <th key={yearIdx} css={s.thAndTd}>
                                    <p css={s.tableText(year, sortCriteria.sortBy)}>{item[`${year}`].toLocaleString()}</p>
                                </th>
                            ))}
                            
                        </tr>
                    ))}
                </tbody>
            </table>
            
        </div>
    );
};

export default AllSggTable;