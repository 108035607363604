/** @jsxImportSource @emotion/react */
import { IoClose } from "react-icons/io5";
import React, { useEffect, useState } from 'react';

const Popup = ({ popupData, selectedCategory, onClickCloseButton, selected }) => {
    const [ s, setS ] = useState(require('./style1'));

    useEffect(() => {
        if(selected === 1) {
            setS(require('./style1'));
        } else if(selected === 2) {
            setS(require('./style2'))
        } else if(selected === 3) {
            setS(require('./style3'))
        } else if(selected === 4) {
            setS(require('./style4'))
        } else if(selected === 5) {
            setS(require('./style5'))
        } else if(selected ===6) {
            setS(require('./style6'))
        }
    }, [selected])

    return (
        <div id="popup" css={s.mainContainer}>
            <div css={s.top}/>
            <p css={s.closer} onClick={onClickCloseButton}><IoClose /></p>
            { selectedCategory && popupData &&
                <div css={s.content}>
                    <header css={s.header}><p>{selectedCategory.nm_kor}</p></header>
                    <p css={s.name}>{popupData.adminNm}</p>
                    <span css={s.text}>{popupData.val !== undefined ? `${popupData.val.toLocaleString()}${selectedCategory.unit}` : "데이터 없음"}</span>
                </div>
            }
        </div>
    );
};

export default Popup;