/** @jsxImportSource @emotion/react */
import axios from 'axios';
import * as s from './style'
import React, { useEffect, useState } from 'react';
import { API_URL } from '../../../config/URL/URL';
import { useNavigate } from 'react-router-dom';
import { MdRemoveRedEye } from 'react-icons/md';
import dayjs from 'dayjs';
import PageComponent from '../atoms/pagination/PageComponent';
import SearchBar from '../atoms/search_bar/SearchBar'
import { getPageSize, getPosts } from '../function/BoardFunction';

const WebzineBoard = ({ selectedBoard, searchOption }) => {
    const navigate = useNavigate();
    const { id: boardId, count, name } = selectedBoard;

    const [ postArray, setPostArray ] = useState([]);
    const [ pageSize, setPageSize ] = useState();
    const [ selectedPage, setSelectedPage ] = useState(1);

    useEffect(() => {
        fetchData(boardId, 1, searchOption, count);

        return () => {
            setSelectedPage(1);
            setPostArray([]);
        }

    }, [selectedBoard])

    useEffect(() => {
        fetchData(boardId, selectedPage, searchOption, count);
    }, [selectedPage])

    const fetchData = async (boardId, page, searchOption, count) => {
        const data = await getPosts(boardId, page, searchOption, count);
        const sortedData = data.sort((a, b) => b.number - a.number);
        
        const size = await getPageSize(boardId, searchOption, count);

        setPageSize(size);
        setPostArray(sortedData);
    }
    
    const onClickPostButtonHandle = () => {
        navigate(`/test/board/writepost/${selectedBoard.id}`)
    }

    const onClickContainerHandle = (item) => {
        navigate(`/test/board/${selectedBoard.id}/${item.id}`)
    }

    const htmlToString = (html) => {
        const tempElement = document.createElement('div');
        const imgTagPattern = /<img\b[^>]*>/g;

        tempElement.innerHTML = html;
        tempElement.innerHTML = tempElement.innerHTML.replace(imgTagPattern, '');

        return tempElement.textContent || tempElement.innerText || '';
    }

    return (
        <div css={s.mainContainer}>
            <div css={s.boardContainer}>
                <header css={s.header}>
                    {name}
                </header>
                <div css={s.postContainer}>
                    {postArray.map((post) => (
                        <div key={post.id} css={s.post} onClick={() => onClickContainerHandle(post)}>
                            {post.thumbnail 
                                ? <div css={s.imgBox}>
                                    <img css={s.thumb} src={post.thumbnail} alt="thumb"/>
                                  </div>
                                : ""
                            }
                            <div css={s.contentBox}>
                                <header css={s.contentHeader}>{post.title}</header>
                                <p css={s.contentMain}>{htmlToString(post.content)}</p>
                                <footer css={s.contentFooter}>
                                    <span css={s.author}>{post.author}</span>
                                    <span css={s.item}>{dayjs(post.registeDate).format('YYYY-MM-DD')}</span>
                                    <span css={s.item}><MdRemoveRedEye /> {post.view}</span>
                                </footer>
                            </div>
                        </div>
                    ))}
                </div>
                <SearchBar selectedBoard={selectedBoard} searchOption={searchOption}/>
                <PageComponent pageSize={pageSize} selectedPage={selectedPage} setSelectedPage={setSelectedPage}/>
                <div css={s.postButtonContainer}>
                    <button css={s.postButton} onClick={onClickPostButtonHandle}>글쓰기</button>
                </div>
            </div>
        </div>
    );
};

export default WebzineBoard;