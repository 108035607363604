/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';


const mainContainer = (isSelected) => css`
    display: flex;
    background-color: ${isSelected ? "#b9b9b95c" : ""};
    align-items: center;
    font-size: 13px;
    height: 18px;
    padding: 1px;
    cursor: pointer;
`

const SelectMemuItem = ({ children, isSelected, onClick, display }) => {
    return (
        <div css={() => mainContainer(isSelected, display)} onClick={onClick} >
            {children}
        </div>
    );
};

export default SelectMemuItem;