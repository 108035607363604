import { css } from "@emotion/react";

export const mainContainer = css`
    display: flex;
    min-height: 278px;
    font-size: 12px;
`
export const topten = css`    
    width: 50%;
`

export const header = css`
    width: 240px;
    font-size: 12px;
    margin-bottom: 5px;
`

export const table = css`
    width: 100%;
    font-size: 11px;
    border: 1px solid gray;
`

export const thead = css`
    width: 100%;
    height: 30px;
    color: #fff;
    background-color: #999999;
`

export const thAndTd = css`
    padding: 5px 0px;
    text-align: center;
    vertical-align: middle;
    border-bottom: 1px solid #a2a2a2;
`
export const lowten = css`
    width: 50%;
`
export const tr = (flag) => css`
    background-color: ${flag ? "#b9b9b95c" : ""};
    &:hover {
        background-color: ${!flag ? "#e8e8e8" : ""};
    }
`


export const rankText = css`
    color: #002755;
`