/** @jsxImportSource @emotion/react */
import * as s from './style'
import React, { useEffect, useRef, useState } from 'react';

import { Editor } from '@toast-ui/editor';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import '@toast-ui/editor/dist/i18n/ko-kr';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import axios from 'axios';
import { API_URL } from '../../../config/URL/URL';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/board/atoms/button/Button';
import FileUploadComponent from '../../../components/board/create_post/file_upload/FileUploadComponent';
import ThumbUploadComponent from '../../../components/board/create_post/thumb/ThumbUploadComponent';

const CreatePost = () => {
    const navigate = useNavigate();
    const params = useParams();
    const editorRef = useRef();
    const [ boards, setBoards ] = useState([]);
    const [ selectedBoard, setSelectedBoard ] = useState("");
    const [ fileList, setFileList ] = useState([]);
    const [ imageList, setImageList ] = useState([]);

    const [ title, setTitle ] = useState("");
    const [ content, setContent ] = useState("");
    const [ thumb, setThumb ] = useState({index: null, image: null})

    const [ postActive, setPostActive ] = useState(false);

    useEffect(() => {
        fetchList();

        if(params.boardIndex) {
            setSelectedBoard(params.boardIndex);

            return () => {
                setSelectedBoard();
            }
        }
    }, [])


    useEffect(() => {
        if(!editorRef.current) {
            const editor = new Editor({
                el: document.querySelector('#editor'),
                height: '700px',
                initialEditType: 'wysiwyg',
                previewStyle: 'vertical',
                hideModeSwitch: true,
                plugins: [[colorSyntax]],
                language: "ko-KR",
              });

            editor.on("change", onChangeEditorHandle)

            editorRef.current = editor;
        }

    }, [editorRef])

    useEffect(() => {
        const StringContent = content.replace(/<[^>]*>?/g, '');

        if(title.length > 0 && StringContent.length > 0) {
            setPostActive(true);
        } else {
            setPostActive(false);
        }

    }, [content, title])

    useEffect(() => {
        const list = extractSrcFromContent(content);
        setImageList(list);
    }, [content])

    const extractSrcFromContent = (content) => {
        const imgRegex = /<img.*?src=['"](.*?)['"].*?>/g;
        const matches = [];
        let match;
        
        while ((match = imgRegex.exec(content)) !== null) {
            matches.push(match[1]);
        }
        
        return matches;
    };


    const fetchList = async () => {
        const data = await getList();
        const boardArray = data.filter((item) => item.type !== 0);
        setBoards(boardArray);
    }

    const getList = async () => {
        try {
            const response = await axios.get(`${API_URL}/board/metadata`);
            return response.data;

        } catch(error) {
            console.log(error);
        }
    }

    const onClickPostButtonHandle = () => {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('author', 'test');
        formData.append('content', content);
        formData.append('boardId', selectedBoard);
        formData.append('thumbnail', thumb.image);
        formData.append('state', 1);
        fileList.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
        });

        savePost(formData);
    }

    const savePost = async (data) => {
        const option = {
            headers: {
                "Content-Type": "multypart/form-data"
            }
        }

        try {
            const response = await axios.post(`${API_URL}/board/post/save`, data, option);
            navigate(`/test/board/${selectedBoard}/${response.data}`);
            return;
        } catch(error) {
            console.log(error);
            alert("게시글 등록에 실패하였습니다.")
            return;
        }
    }

    const onChangeEditorHandle = () => {
        setContent(editorRef.current.getHTML());
    }

    return (
        <div css={s.mainContainer}>
            <div css={s.postContainer}>
                <header css={s.header}>
                    <span>글쓰기</span>
                    <div>
                        <Button theme={"gray"}>임시등록</Button>
                        {postActive 
                            ? <Button theme={"green"} onClick={onClickPostButtonHandle} style={{ marginLeft: '15px'}}>등록</Button>
                            : <Button theme={"gray"} style={{ marginLeft: '15px'}}>등록</Button>
                        }
                    </div>
                </header>
                <div css={s.titleContainer}>
                    <select css={s.boardSelect} value={selectedBoard} onChange={(e) => setSelectedBoard(e.target.value)}>
                        <option css={s.selectPlaceholder} value="" disabled>게시판을 선택해주세요.</option>
                        {boards.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                    </select>
                    <textarea css={s.titleInput} rows="1" placeholder='제목을 입력해 주세요.' name='title' spellCheck="false" onChange={(e) => setTitle(e.target.value)}/>
                </div>
                <div id='editor' style={{width: "100%"}}/>
                <ThumbUploadComponent imageList={imageList} thumb={thumb} setThumb={setThumb} />
                <FileUploadComponent fileList={fileList} setFileList={setFileList}/>
            </div>
        </div>
    );
};

export default CreatePost;

