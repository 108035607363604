import { css } from "@emotion/react";

export const mainContainer = css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: white;
    box-shadow: 10px 10px 4px rgba(0,0,0,0.4);
    /* border: 1px solid #cccccc; */
    border-radius: 5px;
    bottom: 12px;
    min-width: 160px;
    transform: translateX(-50%);

    &::after, &::before {
        top: 100%;
        border: solid transparent;
        content: " ";
        left: 75px;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &::after {
        border-top-color: white;
        border-width: 10px;
        margin-left: -10px;
        
    }

    &::before {
        border-top-color: #cccccc;
        border-width: 11px;
        margin-left: -11px;
    }
`

export const top = css`

`

export const closer = css`
    font-size: 12px;
    text-decoration: none;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    color: #ffffff;
`

export const content = css`
    display: flex;
    width: 100%;
    align-self: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    white-space: nowrap;
`

export const header = css`
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
    padding-right: 20px;
    color: #ffffff;
    background-color: #004F9E;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid #004f9e;
    border-bottom: none;
`

export const textContainer = css`
    border: 1px solid #cccccc;
    border-top: none;
    
    padding: 10px;
`

export const name = css`
    font-size: 13px;
    text-align: right;
`

export const text = css`
    font-size: 12px;
    text-align: right;
`
