/** @jsxImportSource @emotion/react */
import { useLocation, useNavigate } from 'react-router-dom';
import GalleryMenuIcon from './menu_item/GalleryMenuIcon';
import * as s from './style'
import React from 'react';
import { useRecoilState } from 'recoil';
import { logTokenAtom } from '../../../atoms/log/LogTokenStore';
import { createLog } from '../../../atoms/log/LogFunction';

const GalleryLinkMenu = () => {
    const [ logTokenState, setLogTokenState ] = useRecoilState(logTokenAtom);

    const location = useLocation();
    const navigate = useNavigate();

    const onClickGalleryLinkIcon = async (categoryCd) => {
        navigate("/gallery", {state: {categoryCd: categoryCd}});

        const logOption = {
            page: location.pathname,
            eventName: "move_to_gallery",
            eventValue: categoryCd,
            level: 1,
            parentEvent: null,
            sessionToken: logTokenState
        }
        
        const newToken = await createLog(logOption);
        setLogTokenState(newToken);
    }

    return (
        <div css={s.mainContainer}>
            <header css={s.header}>▼ Map GALLERY</header>
            <GalleryMenuIcon size={150} coord={[195, 220]} backgroundColor={"royalblue"} padding={40} src="images/icon-01.png" hoverText={"인구특성"} onClick={() => onClickGalleryLinkIcon("106")}/>
            <GalleryMenuIcon size={120} coord={[50, 250]} backgroundColor={"skyblue"} padding={30} src="images/icon-02.png" hoverText={"사회/복지"} onClick={() => onClickGalleryLinkIcon("404")}/>
            <GalleryMenuIcon size={150} coord={[90, 80]} backgroundColor={"yellowgreen"} padding={35} src="images/icon-03.png" hoverText={"경제구조"} onClick={() => onClickGalleryLinkIcon("202")}/>
            <GalleryMenuIcon size={100} coord={[175, 380]} backgroundColor={"lightgray"} padding={20} src="images/icon-04.png" hoverText={"재정/세금"} onClick={() => onClickGalleryLinkIcon("302")}/>
            <GalleryMenuIcon size={60} coord={[0, 70]} backgroundColor={"dimgray"} padding={7} src="images/icon-05.png"/>
            <GalleryMenuIcon size={80} coord={[220, 20]} backgroundColor={"royalblue"} padding={20} src="images/icon-06.png"/>
            <GalleryMenuIcon size={80} coord={[290, 115]} backgroundColor={"orange"} padding={20} src="images/icon-07.png"/>
            <GalleryMenuIcon size={50} coord={[100, 400]} backgroundColor={"royalblue"} padding={10} src="images/icon-08.png"/>

            <GalleryMenuIcon size={20} coord={[0, 420]} backgroundColor={"gray"}/>
            <GalleryMenuIcon size={30} coord={[15, 340]} backgroundColor={"pink"}/>
            <GalleryMenuIcon size={40} coord={[40, 205]} backgroundColor={"dimgray"}/>
            <GalleryMenuIcon size={30} coord={[50, 380]} backgroundColor={"yellowgreen"}/>
            <GalleryMenuIcon size={30} coord={[120, 25]} backgroundColor={"orange"}/>
            <GalleryMenuIcon size={30} coord={[330, 0]} backgroundColor={"gray"}/>
            <GalleryMenuIcon size={50} coord={[345, 335]} backgroundColor={"skyblue"}/>
        </div>
    );
};

export default GalleryLinkMenu;