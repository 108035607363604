/** @jsxImportSource @emotion/react */
import * as s from './style'
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AutoTypeText from '../../components/main/AutoTypeText';
import GalleryLinkMenu from '../../components/main/gallery_link/GalleryLinkMenu';
import { createLog } from '../../atoms/log/LogFunction';
import { useRecoilState } from 'recoil';
import { logTokenAtom } from '../../atoms/log/LogTokenStore';

const Main = () => {
    const location = useLocation();
    const [ logTokenState, setLogTokenState ] = useRecoilState(logTokenAtom);
    const ref = useRef();
    const navigate = useNavigate();
    
    const onClickMapLinkImg = async (categoryCd) => {
        navigate("/sodamap", {state: {categoryCd: categoryCd}});

        const logOption = {
            page: location.pathname,
            eventName: "start_the_map",
            eventValue: categoryCd,
            sessionToken: logTokenState,
        }
        
        const newToken = await createLog(logOption);
        setLogTokenState(newToken);
    }

    return (
        <div css={s.container} ref={ref}>
            <div css={s.bannerContainer}>
                <div css={s.bannerTextContainer}>
                    <h1 css={s.title}>
                        Look at the map,<br/>
                        Know the indicators<br/>
                        And get insights.
                    </h1>
                    <div css={s.mainText}>
                        <span>SO</span>
                        <AutoTypeText text={"cial"}/>
                        <span>DA</span>
                        <AutoTypeText text={"ta  "}/>
                        <span>MAP</span>
                    </div>
                    <p css={s.subText}>
                        지도로 볼 수 있는 모든 데이터를 시각화하여 지역의 최신 정보 및 이슈,<br/>
                        필요한 정보를 바로바로 보여줍니다.
                    </p>
                </div>
                <GalleryLinkMenu />
            </div>
            <div css={s.mapLinkImgContainer}>
                <header>▼ Start the map</header>
                <div css={s.linkImgBox}>
                    <img css={s.linkImg} onClick={() => onClickMapLinkImg("202")} src="images/start the map-1.png" alt="경제구조"/>
                    <img css={s.linkImg} onClick={() => onClickMapLinkImg("404")} src="images/start the map-2.png" alt="사회/복지"/>
                    <img css={s.linkImg} onClick={() => onClickMapLinkImg("106")} src="images/start the map-3.png" alt="인구특성"/>
                    <img css={s.linkImg} onClick={() => onClickMapLinkImg("301")} src="images/start the map-4.png" alt="재정/세금"/>
                </div>
                
            </div>
        </div>
    );
};

export default Main;