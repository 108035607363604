/** @jsxImportSource @emotion/react */
import React from 'react';
import { Collapse, List, ListItem, ListItemText, css } from '@mui/material';

const imageBox = css`
    display: flex;
    width: 24px;
    height: 24px;
    margin-right: 10px;
`

const TopItem = ({ name, iconSrc, children }) => {
    return (
        <>
            <ListItem>
                <div css={imageBox}>
                    <img src={iconSrc} alt="icon" />
                </div>
                <ListItemText primary={name}/>
            </ListItem>
            <Collapse in={true} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {children}
                </List>
            </Collapse>
        </>
    );
};

export default TopItem;