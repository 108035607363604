/** @jsxImportSource @emotion/react */
import * as s from './style'
import React from 'react';
import { PiPaperclip } from 'react-icons/pi';
import FileIcon from '../../atoms/file_icon/FileIcon';
import { API_URL } from '../../../../config/URL/URL';

const AttachComponent = ({ data }) => {
    const onClickFileHandle = (file) => {
        const url = `${API_URL}/board/file/${file.id}`
        window.open(url, '_blank');
    }

    return (
        <div css={s.mainContainer}>
            <header css={s.header}>
                <PiPaperclip />
                <span css={s.headerText}>첨부파일</span>
            </header>
            <ul>
                {data.file.map((file) => (
                    <li key={file.id} css={s.file} onClick={() => onClickFileHandle(file)}>
                        <FileIcon fileName={file.name} />
                        <span css={s.fileName}>{file.name}</span>
                    </li>
                ))}
            </ul>
            <ul css={s.imageContainer}>
                {data.image.map((image) => (
                    <li key={image.id}>
                        <img css={s.image} src="/images/1a.png" alt='post_image' />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AttachComponent;