import { css } from "@emotion/react";

export const container = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 0px;
`
export const bannerContainer = css`
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
`
export const bannerTextContainer = css`    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
export const title = css`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    font-size: 35px;
    font-weight: 500;
    
    letter-spacing: 3.5px;
    line-height: 150%;
`

export const mainText = css`
    font-size: 45px;
    font-weight: 500;
    letter-spacing: 3.5px;
`

export const subText = css`
    font-size: 18px;
    font-weight: 400;
    line-height: 35px;
`

export const mapLinkImgContainer = css`
    padding-top: 40px;
    align-self: flex-start;
    font-size: 20px;
`

export const linkImgBox = css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 100px;
`

export const linkImg = css`
    width: 250px;
    cursor: pointer;
`


