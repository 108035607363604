/** @jsxImportSource @emotion/react */
import { RiImageAddLine } from "react-icons/ri";
import React, { useRef } from 'react';
import { css } from '@emotion/react';
import { IoMdClose } from "react-icons/io";

const mainContainer = css`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const icon = css`
    font-size: 35px;
    margin-bottom: 10px;
`

const noImage = css`
    display: flex;
    border: 2px dashed #dbdbdb;
    
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #666666;
    box-sizing: border-box;
    flex-direction: column;
`

export const image = css`
    display: flex;
    border: 2px solid #1AF924;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    box-sizing: border-box;
`

export const imageBox = css`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

export const closeButton = css`
    position: absolute;
    top: 5px;
    left: 5px;
    color: red;
`



const ImageInput = ({ selectedImage, setSelectedImage }) => {
    const inputRef = useRef();

    const onChangeInputHandle = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        
        if(file) {
            if(isImage(file.type)) {
                reader.onloadend = () => {
                    setSelectedImage(reader.result);
                };
        
                reader.readAsDataURL(file);
            }
        }
    }

    const onClickContainerHandle = () => {
        if(inputRef.current) {
            inputRef.current.click();
        }
    }

    const onDropHandle = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();

        if(isImage(file.type)) {
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
    
            if(file) {
                reader.readAsDataURL(file);
            }
        }
    }

    const onDragOverHandle = (e) => {
        e.preventDefault();
    }

    const isImage = (type) => {
        const identifier = type.split("/")[0];

        if(identifier === "image") {
            return true;
        } else {
            alert("이미지 파일을 첨부해주세요.");
        }
    }

    return (
        <div css={mainContainer} onClick={onClickContainerHandle} onDrop={onDropHandle} onDragOver={onDragOverHandle}>
            {selectedImage 
                ? <div css={imageBox}>
                    <img src={selectedImage} alt="thumb" css={image}/>
                    <span css={closeButton}><IoMdClose /></span>
                  </div>
                : <div css={noImage}>
                    <RiImageAddLine css={icon}/>
                    <span>대표이미지 업로드</span>
                  </div>
            }

            <input type='file' style={{ display: "none"}} onChange={onChangeInputHandle} ref={inputRef} accept="image/gif, image/jpeg, image/jpg, image/png"/>
        </div>
    );
};

export default ImageInput;