/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';


const ToggleableImg = ({ isToggle, firstImgSource, secondImgSource }) => {
    const [ imgPath, setImgPath ] = useState(firstImgSource);

    useEffect(()=> {
        if(isToggle) {
            setImgPath(secondImgSource)
        } else {
            setImgPath(firstImgSource)
        }
    }, [isToggle])


    return (
        <img src={imgPath} alt='img'/>
    );
};

export default ToggleableImg;