import { css } from "@emotion/react"

export const mainContainer = css`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border: dashed 2px #ddd;
`

export const header = css`
    display: flex;
    width: 100%;
    padding: 30px 0px;
    justify-content: center;
    align-items: center;
    background-color: #ddd;
    cursor: pointer;
`

export const fileList = css`
    padding: 0px 20px;
`

export const file = css`
    display: flex;
    font-size: 16px;
    padding: 8px 0px;
`

export const deleteIcon = css`
    color: #777777;
    margin-right: 5px;
`

export const fileName = css`
    margin-left: 5px;
`
