import axios from 'axios';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'
import { API_URL } from '../../config/URL/URL';

const { persistAtom } = recoilPersist({
  key: "logTokenStorage",
  storage: sessionStorage
});

export const logTokenAtom = atom({
  key: "logToken",
  default: null,
  effects_UNSTABLE: [persistAtom]
});

export const getToken = async () => {
  try {
    const response = await axios.get(`${API_URL}/log/visit`);
    const token = `Bearer ${response.data}`;
    return token;

  } catch(error) {
    console.log(error)
  }
}
