/** @jsxImportSource @emotion/react */
import * as s from './style'
import React, { useEffect, useState } from 'react';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { useRecoilState } from 'recoil';
import { logTokenAtom } from '../../../atoms/log/LogTokenStore';
import { useLocation } from 'react-router-dom';
import { createLog } from '../../../atoms/log/LogFunction';

const YearSelector = ({ selectedYear, setSelectedYear, selectedCategory, displayAll }) => {
    const location = useLocation();
    const [ logTokenState, setLogTokenState ] = useRecoilState(logTokenAtom);
    const [ yearArray, setYearArray ] = useState();

    useEffect(() => {
        if(selectedCategory) {
            initYearArray();
        }
    }, [selectedCategory])

    useEffect(() => {
        if(yearArray) {
            const newSelectedYear = yearArray.find(year => year.selected).value;
            setSelectedYear(newSelectedYear);
        }
    }, [yearArray])

    useEffect(() => {
        if(yearArray && selectedYear) {
            fetchYearDisplay();
        }
    }, [selectedYear])

    const initYearArray = () => {
        const { start_yyyy, end_yyyy } = selectedCategory;
        const newYearArray = Array.from({ length: parseInt(end_yyyy) - parseInt(start_yyyy) + 1},
            (_, index) => ({
                 index: index,
                 value: parseInt(start_yyyy) + index,
                 display: false,
                 selected: false
                }));
        
        const yearArrayIndexs = newYearArray.map(year => year.index); 
        const lastIndex = Math.max(...yearArrayIndexs);

        newYearArray[lastIndex].selected = true;
        
        if(displayAll) {
            newYearArray.forEach((year) => {
                year.display = true;
            })
        } else {
            newYearArray.forEach((year) => {
                if(year.index >= lastIndex - 3) {
                    year.display = true;
                }
            })
        }
                
        setYearArray(newYearArray);
    }

    const fetchYearDisplay = () => {
        const newyearArray = yearArray.map((year) => ({
            ...year,
            selected: year.value === selectedYear,
        }));

        const newSelectedYear = newyearArray.find(year => year.selected);

        if(newSelectedYear.display) {
            setYearArray(newyearArray);
        } else {
            setYearDisplay(newyearArray, newSelectedYear);
        }
    }

    const setYearDisplay = (newyearArray, newSelectedYear) => {
        if(newyearArray.length - newSelectedYear.index > 4) {
            const indexArray = Array.from({length: 4}, (_, index) => index + newSelectedYear.index);
            newyearArray.forEach((year) => {
                year.display = indexArray.includes(year.index);
            })
            setYearArray(newyearArray);
        } else if(newyearArray.length - newSelectedYear.index <= 4) {
            const indexArray = Array.from({length: 4}, (_, index) => newSelectedYear.index - index);
            newyearArray.forEach((year) => {
                year.display = indexArray.includes(year.index);
            })
            setYearArray(newyearArray);
        }
    }

    const onClickYearHandle = async (newYear) => {
        const newyearArray = yearArray.map((year) => ({
            ...year,
            selected: year.index === newYear.index,
        }));
    
        setYearArray(newyearArray);

        const logOption = {
            page: location.pathname,
            eventName: "select_year",
            eventValue: null,
            sessionToken: logTokenState,
            properties: {
                year: newYear.value
            }
          };
      
          const newToken = await createLog(logOption);
          setLogTokenState(newToken);
    }  
    
    const onClickArrowHandle = (operator, active) => {
        if(active) {
            const displayedyearArray = yearArray.filter(year => year.display);
            const indexArray = displayedyearArray.map(year => year.index);
    
            const newIndexArray = indexArray.map(index => operator === "back" ? index - 1 : index + 1);
    
            const newyearArray = yearArray.map((year) => ({
                ...year,
                display: newIndexArray.includes(year.index),
            }));
            
            setYearArray(newyearArray);
        }
    }

    if(yearArray)
    return (
        <div css={s.mainContainer}>
            {displayAll 
                ? "" 
                : <div css={s.arrow(!yearArray[0].display)} onClick={() => onClickArrowHandle("back", !yearArray[0].display)}>{!yearArray[0].display ? <IoIosArrowBack /> : ""}</div>}
            {yearArray.map((year, idx) => (
                <div key={idx} css={[s.item(year.display, year.selected), displayAll && s.disPlayAllItem]} onClick={() => onClickYearHandle(year)}>{year.value}</div>
                ))}
            <div css={s.arrow(!yearArray[yearArray.length - 1].display)} onClick={() => onClickArrowHandle("forward", !yearArray[yearArray.length - 1].display)}>{!yearArray[yearArray.length - 1].display ? <IoIosArrowForward /> : ""}</div>
        </div>
    );
};

export default YearSelector;