/** @jsxImportSource @emotion/react */
import * as s from './style'
import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/board/atoms/sidebar/Sidebar'
import { useLocation, useNavigate } from 'react-router-dom';
import { getBoardComponent } from '../../components/board/function/BoardFunction';

const Board = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [ searchOption, setSearchOption ] = useState({
        type: "title",
        value: ""
    });
    const [ selectedBoard, setSelectedBoard ] = useState();
    const [ BoardComponent, setBoardComponent ] = useState();

    useEffect(() => {
        if(location.pathname === "/test/board") {
            navigate("/test/board/0");
        }

        if(location.state) {
            setSearchOption(location.state.searchOption);
        } else {
            setSearchOption({
                type: "title",
                value: ""
            })
        }
    }, [location])

    useEffect(() => {
        if (selectedBoard) {
            const newBoardComponent = () => getBoardComponent(selectedBoard.type);
            setBoardComponent(newBoardComponent);
        }
    }, [selectedBoard]);

    return (
        <div css={s.mainContainer}>
            <div css={s.sidebarContainer}>
                <Sidebar selectedBoard={selectedBoard} setSelectedBoard={setSelectedBoard} />
            </div>
            <div css={s.boardContainer}>
                { BoardComponent && 
                    <BoardComponent key={selectedBoard.id} selectedBoard={selectedBoard} searchOption={searchOption} />                 
                }
            </div>
        </div>
    );
};

export default Board;