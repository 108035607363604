/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from 'react';

const textStyle = css`
    color: #999999;
    transition: opacity 0.3s ease;
`

const AutoTypeText = ({ text }) => {
    const [ displayText , setDisplayText ] = useState("");
    const typingSpeed = 300;
    const pauseDuration = 500;

    useEffect(() => {
        typeAnimation();
    }, []);

    const typeAnimation = () => {
    
        let currentIndex = 0;
        const Insertinterval = setInterval(() => {
            if (currentIndex <= text.length) {
                setDisplayText(text.substring(0, currentIndex));
                currentIndex++;
            } else {
                clearInterval(Insertinterval);
                setTimeout(() => {
                    deleteAnimation();
                }, pauseDuration);
            }
        }, typingSpeed);
    };

    const deleteAnimation = () => {
        let currentIndex = text.length;
        const deleteInterval = setInterval(() => {
            if (currentIndex >= 0) {
                setDisplayText(text.substring(0, currentIndex));
                currentIndex--;
            } else {
                clearInterval(deleteInterval);
                setTimeout(() => {
                    typeAnimation();
                }, pauseDuration);
            }
        }, typingSpeed);
    };

    return (
        <span css={textStyle}>
            {displayText}
        </span>
    );
};

export default AutoTypeText;