import { css } from "@emotion/react"

export const searchBarContainer = css`
    display: flex;
    justify-content: flex-end;
    padding: 10px 0px 10px 10px;
`

export const typeList = css`
    width: 80px;
    padding: 3px;
    margin-right: 5px;
    border: 1px solid #D0D5DD;
    color: #667085;

    &:focus {
        outline: none;
    }
`

export const searchInput = css`
    width: 150px;
    height: 30px;
    border: 1px solid #D0D5DD;
    border-right: none;
    background-color: #fff;

    &:focus {
        outline: none;
    }
`

export const searchButton = css`
    color: #667085;
    border: 1px solid #D0D5DD;
    border-radius: none;
    cursor: pointer;
`
