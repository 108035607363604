/** @jsxImportSource @emotion/react */
import React from 'react';
import { ListItemButton, ListItemText, css } from '@mui/material';

const imageBox = css`
    display: flex;
    width: 24px;
    height: 24px;
    margin-right: 10px;
`

const Item = ({ name, iconSrc, selected, onClick, padding }) => {
    return (
        <ListItemButton sx={{ pl: padding }} selected={selected} onClick={onClick}>
            <div css={imageBox}>
                <img src={iconSrc} alt="icon" />
            </div>
            <ListItemText primary={name}/>
        </ListItemButton>
    );
};

export default Item;