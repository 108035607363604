import { css } from "@emotion/react";

export const table = css`
    width: 100%;
    font-size: 11px;
    
    /* border: 1px solid gray; */
    border: none;
    margin: 5px 0px 40px 0px;
`

export const thead = css`
    width: 100%;
    height: 30px;
    color: #667085;
    background-color: #FCFCFD;
    
    border-top: 1px solid #EAECF0;
`

export const tbody = css`
    font-weight: 400;
`

export const tr = css`
    &:hover {
        background-color: #F2F4F7;
    }
`

export const thAndTd = css`
    height: 36px;
    text-align: center;
    vertical-align: middle;
    border-bottom: 1px solid #EAECF0;
    font-size: 14px;
    color: #667085;
`

export const pointer = css`
    cursor: pointer;
`

export const number = css`
    width: 5%;
`

export const title = css`
    width: 60%;
`

export const author = css`
    width: 15%;
`

export const date = css`
    width: 10%;
`

export const view = css`
    width: 5%;
`