/** @jsxImportSource @emotion/react */
import * as s from './style'
import React, { useEffect, useRef, useState } from 'react';
import CategoryMenu from '../../components/sodamap/category_menu/CategoryMenu';
import SidoList from '../../components/sodamap/sido_list/SidoList';
import YearSelector from '../../components/sodamap/year_selector/YearSelector';
import SummaryTable from '../../components/sodamap/summary_table/SummaryTable';
import SummaryGraph from '../../components/sodamap/summary_graph/SummaryGraph';
import { BsTable } from "react-icons/bs";
import axios from 'axios';
import { API_URL } from '../../config/URL/URL'
import AllSggTable from '../../components/sodamap/all_sgg_table/AllSggTable';
import CustomMap from '../../components/sodamap/map/CustomMap';
import ModalOpenButton from '../../components/sodamap/icon_button/modalOpenButton';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { logTokenAtom } from '../../atoms/log/LogTokenStore';
import { createLog } from '../../atoms/log/LogFunction';

const Sodamap = () => {
    const location = useLocation();
    const [ logTokenState, setLogTokenState ] = useRecoilState(logTokenAtom);
    const ref = useRef();

    const [ selectedCategory, setSelectedCategory ] = useState();
    const [ selectedSido, setSelectedSido ] = useState();
    const [ selectedYear, setSelectedYear ] = useState();
    const [ selectedSgg, setSelectedSgg ] = useState({ adminCd: "", adminNm: ""});

    const [ data, setData ] = useState();

    const [ allSggTableFlag, setAllSggTableFlag ] = useState(false);

    useEffect(() => {
        if(selectedCategory) {
            fetchData(selectedCategory.categoryCd);
        }
    }, [selectedCategory])

    const fetchData = async (categoryCd) => {
        const newData = await getDataByCategory(categoryCd);
        setData(newData);
    }

    const getDataByCategory = async (categoryCd) => {
        const option = {
            params: {
                category_cd: categoryCd
            }
        }
        try {
            const response = await axios.get(`${API_URL}/sodamap/category/data`, option);
            setData(response.data)

            return response.data;
        } catch(error){
            console.log(error)
        }
    }

    const onClickOpenAllSggTableButton = async () => {
        setAllSggTableFlag(!allSggTableFlag);

        const logOption = {
            page: location.pathname,
            eventName: "click_allregiontable_button",
            eventValue: null,
            sessionToken: logTokenState,
            properties: {
                year: selectedYear,
                categoryCd: selectedCategory.categoryCd,
                action: !allSggTableFlag ? "open" : "close"
            }
          };
      
          const newToken = await createLog(logOption);
          setLogTokenState(newToken);
    }

    return (
        <div css={s.mainContainer} ref={ref}>
            <div css={s.tableContainer}>
                <header css={s.header}>
                    <span>다양한 데이터를 확인할 수 있어요!</span>
                    <SidoList setSelectedSido={setSelectedSido} selectedYear={selectedYear} selectedCategory={selectedCategory}/>
                </header>

                <CategoryMenu selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}/>
                
                <div css={s.yearSelectorContainer}>
                    <span css={s.categoryName}>{selectedCategory ? selectedCategory.nm_kor : ""}</span>
                    <YearSelector selectedYear={selectedYear} setSelectedYear={setSelectedYear} selectedCategory={selectedCategory} />
                    <ModalOpenButton icon={BsTable} onClick={onClickOpenAllSggTableButton}>전국통계보기</ModalOpenButton>
                </div>

                <SummaryTable data={data} selectedYear={selectedYear} selectedCategory={selectedCategory} selectedSgg={selectedSgg} setSelectedSgg={setSelectedSgg}/>

                {allSggTableFlag
                    ? <AllSggTable data={data} selectedYear={selectedYear} selectedCategory={selectedCategory} selectedSgg={selectedSgg} setSelectedSgg={setSelectedSgg} setFlag={setAllSggTableFlag}/>
                    : <SummaryGraph data={data} selectedCategory={selectedCategory} selectedSgg={selectedSgg}/>
                }

            </div>
            <div css={s.mapContainer}>
                <header css={s.header}>
                    <YearSelector selectedYear={selectedYear} setSelectedYear={setSelectedYear} selectedCategory={selectedCategory} displayAll={true}/>
                </header>
                <CustomMap data={data} selectedCategory={selectedCategory} selectedYear={selectedYear} selectedSido={selectedSido} selectedSgg={selectedSgg} setSelectedSgg={setSelectedSgg} />
            </div>
        </div>
    );
};

export default Sodamap;