/** @jsxImportSource @emotion/react */
import * as s from './style'
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { GEOSERVER } from '../../../config/URL/URL';
import Map from 'ol/Map';
import { Overlay, View } from 'ol';
import Attribution from 'ol/control/Attribution';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { getCenter } from 'ol/extent';
import { ImageWMS, TileWMS, XYZ } from 'ol/source';
import TileLayer from 'ol/layer/Tile';
import ImageLayer from 'ol/layer/Image';
import VectorLayer from 'ol/layer/Vector';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Popup from './popup/Popup';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { logTokenAtom } from '../../../atoms/log/LogTokenStore';
import { createLog } from '../../../atoms/log/LogFunction';

const CustomMap = ({ data, selectedCategory, selectedYear, selectedSido, selectedSgg, setSelectedSgg }) => {
    const location = useLocation();
    const [ logTokenState, setLogTokenState ] = useRecoilState(logTokenAtom);

    const mapRef = useRef();
    const [ overlayState, setOverlayState ] = useState();
    const [ viewState, setViewState ] = useState();
    const [ popupData, setPopupData ] = useState({ adminNm: "", val: "" });
    
    const [ dataLayerState, setDataLayerState ] = useState();
    const [ selectedSggLayerState, setSelectedSggLayerState ] = useState();
    const [ selectedSidoLayerState, setSelectedSidoLayerState ] = useState();

    const [ clickedCoordinate, setClickedCoordinate ] = useState(); 
    
    useEffect(() => {
        setInitMap();
    }, [])

    useEffect(() => {
        if(selectedCategory && selectedYear) {
            const dataLayerSource = getDataLayerSource(selectedYear, selectedCategory.nm_en);
            dataLayerState.setSource(dataLayerSource);
        }

    }, [selectedCategory, selectedYear])

    useEffect(() => {
        if(selectedSido) {
            fetchVectorLayer(selectedSidoLayerState, selectedSido.sidoCd).then((borderLayerSource) =>
            viewState.fit(borderLayerSource.getFeatures()[0].getGeometry())
            );
        } else {
            resetBorderLayer(selectedSidoLayerState);
        }
    }, [selectedSido])

    useEffect(() => {
        if(clickedCoordinate) {
            fetchSelectedSgg();
        }

        return (() => {
            setClickedCoordinate();
        })
    }, [clickedCoordinate])


    useEffect(() => {
        if(data && selectedYear && selectedSgg.adminCd !== "") {
            fetchVectorLayer(selectedSggLayerState, selectedSgg.adminCd).then((borderLayerSource) => {
                overlayState.setPosition(getCenter(borderLayerSource.getExtent()))
                const foundData = data.find(item => item.year === selectedYear.toString() && item.adminCd.trim() === selectedSgg.adminCd.trim());

                if(foundData) {
                    setPopupData(foundData);
                } else {
                    setPopupData({adminNm: "", val: ""});
                }
            })
        } else if(selectedSgg.adminCd === "" && popupData.adminNm !== "") {
            overlayState.setPosition(undefined);
            resetBorderLayer(selectedSggLayerState)
            
            setPopupData({adminNm: "", val: ""});
        }
        
    }, [selectedSgg, selectedYear, data])
    
    const setInitMap = () => {
        if(!mapRef.current) {
            const vworldLayer = new TileLayer({
                title: 'VWorld white Map',
                visible: true,
                type: 'white',
                source: new XYZ({
                    url: 'https://xdworld.vworld.kr/2d/white/service/{z}/{x}/{y}.png',
                    attributions: '&copy; <a href="http://map.vworld.kr">V-World Map</a>'
                })
            })

            const sidoWmsLayer = new ImageLayer({
                properties: {
                    layerName: 'sido_wms'
                },
                source: new ImageWMS({
                    url: GEOSERVER + '/geoserver1/wms',
                    params: { 'LAYERS': 'gis_solution:ts_admin_sido' },
                    ratio: 1,
                    serverType: 'geoserver',
                }),
                opacity: 0.8,
                zIndex: 99
            })

            const sggWmsLayer = new ImageLayer({
                properties: {
                    layerName: 'sgg_wms'
                },
                source: new ImageWMS({
                    url: GEOSERVER + '/geoserver1/wms',
                    params: { 'LAYERS': 'gis_solution:ts_admin_sgg' },
                    ratio: 1,
                    serverType: 'geoserver',
                }),
                opacity: 0.8,
                zIndex: 98
            })

            const dataLayer = new TileLayer({
                properties: {
                    layerName: 'data_layer'
                }
            });

            setDataLayerState(dataLayer);

            const sidoBorderStyle = new Style({
                stroke: new Stroke({
                    color: 'yellow',
                    width: 5
                })
             })

            
            const sggBorderStyle = new Style({
                stroke: new Stroke({
                    color: '#FF9112',
                    width: 3
                })
            });

            const selectedSidoLayer = new VectorLayer({
                properties: ({
                    layerName: 'selected_sido_border',
                    tableName: "ts_admin_sido",
                    condition: `sido_cd=`
                }),
                style: sidoBorderStyle,
            });

            setSelectedSidoLayerState(selectedSidoLayer);

            const selectedSggLayer = new VectorLayer({
                properties: ({
                    layerName: 'selected_Sgg_border',
                    tableName: "vl_data",
                    condition: `admin_cd=`
                }),
                style: sggBorderStyle
            });

            setSelectedSggLayerState(selectedSggLayer);

            const overlay = new Overlay({
                element: document.getElementById('popup'),
                autoPan: true,
                autoPanAnimation: {
                duration: 250,
                },
            });

            setOverlayState(overlay);

            const view = new View({
                center: [1.416517405965338E7, 4294546.35698363],
                zoom: 7.5,
                maxZoom: 13,
                minZoom: 7
            })

            setViewState(view);

            const map = new Map({
                layers: [ vworldLayer, sidoWmsLayer, sggWmsLayer, dataLayer, selectedSidoLayer, selectedSggLayer ],
                target: "map_area",
                view: view,
                overlays: [overlay]
            });

            const attribution = new Attribution({
                collapseLabel: "»"
            })
            map.addControl(attribution)
            map.on('click', onClickMapHandle);

            mapRef.current = map;
        }
    }

    const getDataLayerSource = (year, category_nm_en) => {
        const dataLayerSource = new TileWMS({
            url: GEOSERVER + '/geoserver/wms',
            params: {
                'LAYERS': 'gis_solution:vl_data',
                'viewparams': 'year:' + year + ';keyword:' + category_nm_en + ';',
                'STYLES': 'sty_' + category_nm_en + '_sgg',
            },
            ratio: 1,
            serverType: 'geoserver',
        })
    
        return dataLayerSource;
    }

    const fetchVectorLayer = async (layer, code) => {
        const { tableName, condition } = layer.getProperties();

        try {
            const option = {
                tableName: tableName,
                condition: condition + code
            };

            const sourceData = await getVectorLayerSource(option);

            const borderLayerSource = new VectorSource({
                features: new GeoJSON().readFeatures(sourceData.features[0])
            });

            layer.setSource(borderLayerSource);

            return borderLayerSource;
        } catch(error) {
            console.log(error)
        }
    }

    const resetBorderLayer = (layer) => {
        if(layer) {
            const borderLayerSource = new VectorSource();
            layer.setSource(borderLayerSource);
        }
    }

    const getVectorLayerSource = async ({tableName, condition}) => {
        const option = {
            params: {
                service: 'WFS',
                version: '1.3.0',
                request: 'GetFeature',
                typeName: `gis_solution:${tableName}`,
                maxFeatures: 50,
                outputFormat: 'application/json',
                CQL_FILTER: condition,
            }
        };

        try {
            const response = await axios.get(`${GEOSERVER}/geoserver/gis_solution/ows`, option);
            return response.data;
        } catch(error) {
            console.log(error)
        }
    }

    const onClickMapHandle = (e) => {
        setClickedCoordinate(e.coordinate);
    }

    const fetchSelectedSgg = async () => {
        const SggData = await getSggByCoord(clickedCoordinate);
        if(SggData) {
            setSelectedSgg({
                adminCd: SggData.admin_cd.toString(),
                adminNm: SggData.admin_nm
            })

            const logOption = {
                page: location.pathname,
                eventName: "select_sgg_map",
                eventValue: null,
                sessionToken: logTokenState,
                properties: {
                    year: selectedYear,
                    categoryCd: selectedCategory.categoryCd,
                    adminCd: SggData.admin_cd.toString(),
                    adminNm: SggData.admin_nm
                }
              };
          
              const newToken = await createLog(logOption);
              setLogTokenState(newToken);

        } else {
            setSelectedSgg({
                adminCd: "",
                adminNm: ""
            })
        }
    }

    const getSggByCoord = async (coordinate) => {
        const url = dataLayerState.getSource().getFeatureInfoUrl(
            coordinate,
            viewState.getResolution(),
            'EPSG:3857',
            { 'INFO_FORMAT': 'application/json' }
        )
        
        try {
            const response = await axios.get(url);
            const SggData = response.data.features[0].properties;
        
            return SggData;

        } catch (error) {
            return null
        }
    }

    const onClickCloseButton = () => {
        overlayState.setPosition(undefined);
        resetBorderLayer(selectedSggLayerState);
        setSelectedSgg({
            adminCd: "",
            adminNm: ""
        })
    }

    return (
        <div css={s.mainContainer}>
            <div id="map_area" css={s.mapArea}>
                <div css={s.mapPost}>
                    <div css={s.legend}>
                        { selectedCategory && <img src={`images/range/${selectedCategory.nm_en}.png`} alt={selectedCategory.nm_en}/> }
                    </div>
                </div>
            </div>
            <Popup popupData={popupData} selectedCategory={selectedCategory} onClickCloseButton={onClickCloseButton}/> 
        </div>
    );
};

export default CustomMap;