/** @jsxImportSource @emotion/react */
import { useNavigate } from 'react-router-dom';
import * as s from './style'
import React from 'react';


const Table = ({ data, selectedBoard }) => {
    const navigate = useNavigate();

    const onClickTitleHandle = (item) => {
        navigate(`/test/board/${selectedBoard.id}/${item.id}`)
    }

    if(data)
    return (
        <table css={s.table}>
            <thead css={s.thead}>
                <tr>
                    <th css={[s.thAndTd, s.number]}>No.</th>
                    <th css={[s.thAndTd, s.title]}>제목</th>
                    <th css={[s.thAndTd, s.author]}>작성자</th>
                    <th css={[s.thAndTd, s.date]}>등록일</th>
                    <th css={[s.thAndTd, s.view]}>조회</th>
                </tr>
            </thead>
            <tbody css={s.tbody}>
                {data.map((item) => (
                    <tr key={item.id} css={s.tr}>
                        <td css={s.thAndTd}>{item.number}</td>
                        <td css={[ s.thAndTd, s.pointer ]} onClick={() => onClickTitleHandle(item)}>{item.title}</td>
                        <td css={s.thAndTd}>{item.author}</td>
                        <td css={s.thAndTd}>{item.registeDate}</td>
                        <td css={s.thAndTd}>{item.view}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Table;