/** @jsxImportSource @emotion/react */
import dayjs from 'dayjs';
import * as s from './style'

import React, { useEffect, useState } from 'react';

const CommentComponent = ({ data }) => {
    const [ content, setContent ] = useState([]); 

    useEffect(() => {
        if(data) {
            fetchContent();
        }
    }, [data])

    const fetchContent = () => {
        const text = data.content.split("<br>");
        setContent(text);
    }

    return (
        <div css={s.mainContainer}>
            <header css={s.header}>{data.author}</header>
            <main>
                {content.map((line, idx) => (
                    <p key={idx} css={s.contentText}>{line}</p>
                ))}
            </main>
            <footer css={s.footer}>{dayjs(data.registeDate).format("YYYY-MM-DD HH:MM")}</footer>
        </div>
    );
};

export default CommentComponent;