import { css } from "@emotion/react"

export const mainContainer = css`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    border: 1px solid #dbdbdb;
`

export const item = css`
    display: flex;
    width: 150px;
    height: 150px;
    margin: 10px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
`

export const selected = (isSelected) => css`
    border: ${isSelected ? "2px solid #1AF924" : "none"};
`

export const image = css`
    height: 100%;
    object-fit: cover;
`