/** @jsxImportSource @emotion/react */
import * as s from './style'
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdRemoveRedEye } from 'react-icons/md';
import dayjs from 'dayjs';
import SearchBarScroll from '../atoms/search_bar_scroll/SearchBarScroll';
import { getPageSize, getPosts } from '../function/BoardFunction';

const WebzineBoardScroll = ({ selectedBoard }) => {
    const navigate = useNavigate();
    const lastRef = useRef();
    const { id: boardId, count, name } = selectedBoard;

    const [ postArray, setPostArray ] = useState([]);
    const [ pageSize, setPageSize ] = useState();
    const [ selectedPage, setSelectedPage ] = useState(1);
    const [ searchOption, setSearchOption ] = useState({type: "all", value: ""});

    const [ refresh, setRefresh ] = useState(false);

    useEffect(() => {
        fetchData(boardId, selectedPage, searchOption, count);
        fetchSize(boardId, searchOption, count);
    }, [])
    
    useEffect(() => {
        if(pageSize) {
            const observerService = (entries, observer) => {
                entries.forEach(entry => {
                    if(entry.isIntersecting && selectedPage < pageSize){
                        setRefresh(true);
                    }
                })
            }
            
            const observer = new IntersectionObserver(observerService, {threshold: 1});
            observer.observe(lastRef.current);
        }
    }, [pageSize])

    useEffect(() => {
        if(refresh) {
            fetchData(boardId, selectedPage, searchOption, count);
        }
    }, [refresh, selectedPage])

    useEffect(() => {
        if(searchOption.value !== "") {
            fetchSize(boardId, searchOption, count);
            setSelectedPage(1);
            setPostArray([]);
            setRefresh(true);
        }
    }, [searchOption])

    const fetchSize = async (boardId, searchOption, count) => {
        const size = await getPageSize(boardId, searchOption, count);
        setPageSize(size);
    }

    const fetchData = async (boardId, page, searchOption, count) => {
        const data = await getPosts(boardId, page, searchOption, count);

        if(data.length > 0) {
            const sortedData = data.sort((a, b) => b.number - a.number);
    
            setPostArray([...postArray, ...sortedData]);
        }
        
        setSelectedPage(selectedPage + 1);
        setRefresh(false);
    }

    const onClickPostButtonHandle = () => {
        navigate(`/test/board/writepost/${selectedBoard.id}`)
    }

    const onClickContainerHandle = (item) => {
        navigate(`/test/board/${selectedBoard.id}/${item.id}`)
    }
    
    const htmlToString = (html) => {
        const tempElement = document.createElement('div');
        const imgTagPattern = /<img\b[^>]*>/g;

        tempElement.innerHTML = html;
        tempElement.innerHTML = tempElement.innerHTML.replace(imgTagPattern, '');

        return tempElement.textContent || tempElement.innerText || '';
    }

    return (
        <div css={s.mainContainer}>
            <div css={s.boardContainer}>
                <header css={s.header}>
                    <button css={s.postButton} onClick={onClickPostButtonHandle}>글쓰기</button>
                    <span>{name}</span>
                    <SearchBarScroll selectedBoard={selectedBoard} searchOption={searchOption} setSearchOption={setSearchOption}/>
                </header>
                <div css={s.postContainer}>
                    {postArray.map((post) => (
                        <div key={post.id} css={s.post} onClick={() => onClickContainerHandle(post)}>
                            {post.thumbnail 
                                ? <div css={s.imgBox}>
                                    <img css={s.thumb} src={post.thumbnail} alt="thumb"/>
                                  </div>
                                : ""
                            }

                            <div css={s.contentBox}>
                                <header css={s.contentHeader}>{post.title}</header>
                                <p css={s.contentMain}>{htmlToString(post.content)}</p>
                                <footer css={s.contentFooter}>
                                    <span css={s.author}>{post.author}</span>
                                    <span css={s.item}>{dayjs(post.registeDate).format('YYYY-MM-DD')}</span>
                                    <span css={s.item}><MdRemoveRedEye /> {post.view}</span>
                                </footer>
                            </div>
                        </div>
                    ))}
                    <div ref={lastRef}></div>
                    {pageSize === selectedPage - 1
                        ? <div css={s.lastPage}>
                            <span>마지막 게시글입니다.</span>
                          </div>
                        : ""
                    }
                </div>
            </div>
        </div>
    );
};

export default WebzineBoardScroll;