/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';

import { BiSolidFileTxt } from "react-icons/bi";
import { RiFileHwpFill } from "react-icons/ri";
import { FaFile, FaFileArchive, FaFileExcel, FaFileImage, FaFilePdf, FaFilePowerpoint, FaFileWord } from "react-icons/fa";

const color = (color) => css`
    color: ${color};
`

const FileIcon = ({ fileName }) => {
    const [ data, setData ] = useState();

    const list = [
        {
            extension: "jpg",
            icon: <FaFileImage />
        },
        {
            extension: "jpeg",
            icon: <FaFileImage />
        },
        {
            extension: "png",
            icon: <FaFileImage />
        },
        {
            extension: "gif",
            icon: <FaFileImage />
        },
        {
            extension: "txt",
            icon: <BiSolidFileTxt />
        },
        {
            extension: "doc",
            icon: <FaFileWord />,
            color: "#165BC4"
        },
        {
            extension: "docx",
            icon: <FaFileWord />,
            color: "#165BC4"
        },
        {
            extension: "hwp",
            icon: <RiFileHwpFill />,
            color: "#209DDF"
        },
        {
            extension: "xlsx",
            icon: <FaFileExcel />,
            color: "#107C41"
        },
        {
            extension: "xls",
            icon: <FaFileExcel />,
            color: "#107C41"
        },
        {
            extension: "pptx",
            color: "#C43E1C",
            icon: <FaFilePowerpoint />
        },
        {
            extension: "ppt",
            color: "#C43E1C",
            icon: <FaFilePowerpoint />
        },
        {
            extension: "zip",
            icon: <FaFileArchive />
        },
        {
            extension: "apk",
            icon: <FaFileArchive />
        },
        {
            extension: "rar",
            icon: <FaFileArchive />
        },
        {
            extension: "7z",
            icon: <FaFileArchive />
        },
        {
            extension: "tar",
            icon: <FaFileArchive />
        },
        {
            extension: "pdf",
            color: "#C83636",
            icon: <FaFilePdf />
        }
    ]

    useEffect(() => {
        const extension = fileName.split(".")[1];
        const findData = list.find((item) => item.extension === extension);

        if(findData) {
            setData(findData);
        } else {
            setData({
                icon: <FaFile />
            })
        }
    }, [fileName])

    if(data)
    return (
        <span css={color(data.color)}>
            {data.icon}
        </span>
    );
};

export default FileIcon;