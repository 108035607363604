/** @jsxImportSource @emotion/react */
import * as s from './style'
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdRemoveRedEye } from 'react-icons/md';
import { FaRegImage } from "react-icons/fa6";
import dayjs from 'dayjs';
import PageComponent from '../atoms/pagination/PageComponent';
import SearchBar from '../atoms/search_bar/SearchBar'
import { getPageSize, getPosts } from '../function/BoardFunction';

const GalleryBoard = ({ selectedBoard, searchOption }) => {
    const navigate = useNavigate();
    const { id: boardId, count, name } = selectedBoard;

    const [ postArray, setPostArray ] = useState([]);
    const [ pageSize, setPageSize ] = useState();
    const [ selectedPage, setSelectedPage ] = useState(1);

    useEffect(() => {
        fetchData(boardId, 1, searchOption, count);

        return () => {
            setSelectedPage(1);
            setPostArray([]);
        }

    }, [selectedBoard])

    useEffect(() => {
        fetchData(boardId, selectedPage, searchOption, count);
    }, [selectedPage])

    const fetchData = async (boardId, page, searchOption, count) => {
        const data = await getPosts(boardId, page, searchOption, count);
        const sortedData = data.sort((a, b) => b.number - a.number);
        
        const size = await getPageSize(boardId, searchOption, count);

        setPageSize(size);
        setPostArray(sortedData);
    }

    const onClickPostButtonHandle = () => {
        navigate(`/test/board/writepost/${selectedBoard.id}`)
    }

    const onClickContainerHandle = (item) => {
        navigate(`/test/board/${selectedBoard.id}/${item.id}`)
    }

    return (
        <div css={s.mainContainer}>
            <div css={s.boardContainer}>
                <header css={s.header}>
                    {name}
                </header>
                <div css={s.postContainer}>
                    {postArray.map((post) => (
                        <div key={post.id} css={s.post} onClick={() => onClickContainerHandle(post)}>
                            <div css={s.imgBox}>
                                {post.thumbnail
                                    ? <img css={s.thumb} src={post.thumbnail} alt="thumb"/>
                                    : <div css={s.noImage}>
                                        <span css={s.noImageIcon}><FaRegImage /></span>
                                        <span>No Image</span>
                                      </div>
                                }
                            </div>
                            <div css={s.contentBox}>
                                <div css={s.contentHeader}>
                                    <span css={s.title}>{post.title}</span>
                                </div>
                                <div>
                                    <span css={s.author}>{post.author}</span>
                                </div>
                                <div css={s.contentFooter}>
                                    <span css={s.item}>{dayjs(post.registeDate).format('YYYY-MM-DD')}</span>
                                    <span css={s.item}><MdRemoveRedEye /> {post.view}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <SearchBar selectedBoard={selectedBoard} searchOption={searchOption}/>
                <PageComponent pageSize={pageSize} selectedPage={selectedPage} setSelectedPage={setSelectedPage}/>
                <div css={s.postButtonContainer}>
                    <button css={s.postButton} onClick={onClickPostButtonHandle}>글쓰기</button>
                </div>
            </div>
        </div>
    );
};

export default GalleryBoard;