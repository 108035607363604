import { css } from "@emotion/react"

export const mainContainer = css`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px 0px;
`

export const postContainer = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 25px 15px;
    background-color: #fff;
    
    border: 1px solid #dbdbdb;
`

export const header = css`
    display: flex;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid #000000;
    font-size: 22px;
    color: #555555;
    justify-content: space-between;
    align-items: center;
`

export const titleContainer = css`
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
`

export const boardSelect = css`
    width: 200px;
    border:1px solid #c8c8c8;
    padding: 5px 10px;

    &:focus {
        outline: none;
    }
`

export const selectPlaceholder = css`
    display: none;
    color: #dbdbdb;
`

export const titleInput = css`
    display: flex;
    font-size: 16px;
    margin-top: 10px;
    padding: 10px;
    background-color: #FCFCFD;
    border: 2px solid #dbdbdb;
    border-radius: 6px;
    resize: none;
    line-height: 22px;
    font-weight: 700;

    &:focus {
        outline: none;
    }
`
