import { css } from "@emotion/react"

export const mainContainer = css`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px 0px;
`

export const boardContainer = css`
    display: flex;
    flex-direction: column;
    width: 1100px;
    padding: 10px 10px 10px 20px;
`

export const header = css`
    display: flex;
    padding-top: 20px;
    padding-bottom: 45px;
    font-size: 24px;
    font-weight: 700;
    justify-content: center;
`

export const postContainer = css`
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    flex-wrap: wrap;
`

export const post = css`
    display: flex;
    width: 31%;
    height: 240px;
    margin: 5px 12px;
    flex-direction: column;
    box-sizing: border-box;
    cursor: pointer;
`

export const imgBox = css`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 75%;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid #eaeaea;
`

export const thumb = css`
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
`

export const noImage = css`
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #dbdbdb;
`

export const noImageIcon = css`
    font-size: 80px;
`

export const contentBox = css`
    display: flex;
    flex: 1;
    padding: 8px;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
`

export const contentHeader = css`

`

export const title = css`
    display: -webkit-box;
    font-size: 13px;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
`

export const contentFooter = css`
    display: flex;
    align-items: center;
    font-size: 11px;
    color: #a6a7a9;
`

export const author = css`
    color: #232324;
    font-size: 11px;
    padding-right: 10px;
`

export const item = css`
    padding-right: 5px;
`

export const postButtonContainer = css`
    display: flex;
    padding-top: 30px;
    justify-content: flex-end;
`

export const postButton = css`
    display: flex;
    width: 100px;
    height: 40px;
    align-items: center;
    justify-content: center;
    background-color: #3671c8;
    border: none;
    color: #fff;
    cursor: pointer;
`