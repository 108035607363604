/** @jsxImportSource @emotion/react */
import { useLocation } from 'react-router-dom';
import * as s from './style';
import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { logTokenAtom } from '../../../atoms/log/LogTokenStore';
import { createLog } from '../../../atoms/log/LogFunction';

const SummaryTable = ({ data, selectedYear, selectedCategory, selectedSgg, setSelectedSgg }) => {
  const location = useLocation();
  const [ logTokenState, setLogTokenState ] = useRecoilState(logTokenAtom);

  const [ highChartData, setHighChartData ] = useState([]);
  const [ lowChartData, setLowChartData ] = useState([]);

  useEffect(() => {
    setCharts();
  }, [data, selectedYear]);

  const setCharts = () => {
    if(data) {
      const filteredData = data.filter(item => (item.adminCd.trim() === "39" || item.adminCd.trim() === "29" || item.adminCd.trim().length >= 5) && item.val !== null)
      const selectedYearData = filteredData.filter(item => item.year === selectedYear.toString());
  
      const sortedData = selectedYearData.sort((a, b) => b.val - a.val);
  
      const highRankData = sortedData.slice(0, 10);
      highRankData.forEach((item, idx) => {
        item.rank = idx + 1;
      })
  
      const lowRankData = sortedData.slice(sortedData.length - 10);
      lowRankData.sort((a, b) => a.val - b.val);
  
      lowRankData.forEach((item, idx) => {
        item.rank = sortedData.length - idx;
      })
  
      setHighChartData(highRankData);
      setLowChartData(lowRankData);
    }
  }

  const onClickRowHandle = async (data) => {
    if(data.adminCd !== selectedSgg.adminCd) {
      setSelectedSgg({
        adminCd: data.adminCd,
        adminNm: data.adminNm
      });

      const logOption = {
        page: location.pathname,
        eventName: "select_sgg_ranktable",
        eventValue: null,
        sessionToken: logTokenState,
        properties: {
            year: selectedYear,
            categoryCd: selectedCategory.categoryCd,
            adminCd: data.adminCd,
            adminNm: data.adminNm
        }
      };
  
      const newToken = await createLog(logOption);
      setLogTokenState(newToken);

    } else if(data.adminCd === selectedSgg.adminCd) {
      setSelectedSgg({
        adminCd: "",
        adminNm: ""
      })
    }
  }

  if(selectedCategory)
  return (
    <div css={s.mainContainer}>
      <div css={s.topten}>
        <header css={s.header}>상위 10</header>
        <table css={s.table}>
            <thead css={s.thead}>
                <tr>
                    <th css={s.thAndTd}>순위</th>
                    <th css={s.thAndTd}>지역</th>
                    <th css={s.thAndTd}>{selectedCategory.unit}</th>
                </tr>
            </thead>
            <tbody>
                {highChartData.map((data, idx) => (
                  <tr css={s.tr(data.adminNm === selectedSgg.adminNm)} key={idx} onClick={() => onClickRowHandle(data)}>
                      <td css={s.thAndTd}><b css={s.rankText}>{data.rank}</b></td>
                      <td css={s.thAndTd}>{data.adminNm}</td>
                      <td css={s.thAndTd}>{data.val.toLocaleString()}</td>
                  </tr>
                ))}
            </tbody>
        </table>
      </div>
      <div css={s.lowten}>
        <header css={s.header}>하위 10</header>
        <table css={s.table}>
            <thead css={s.thead}>
                <tr>
                    <th css={s.thAndTd}>순위</th>
                    <th css={s.thAndTd}>지역</th>
                    <th css={s.thAndTd}>{selectedCategory.unit}</th>
                </tr>
            </thead>
            <tbody>
                {lowChartData.map((data, idx) => (
                  <tr css={s.tr(data.adminNm === selectedSgg.adminNm)} key={idx} onClick={() => onClickRowHandle(data)}>
                      <td css={s.thAndTd}><b css={s.rankText}>{data.rank}</b></td>
                      <td css={s.thAndTd}>{data.adminNm}</td>
                      <td css={s.thAndTd}>{data.val.toLocaleString()}</td>
                  </tr>
                ))}
            </tbody>
        </table>
      </div>
    </div>
  );
};

export default SummaryTable;