import { css } from "@emotion/react";

export const mainContainer = css`

`

export const mapArea = css`
    display: flex;
    width: 700px;
    /* width: 100%; */
    height: 95vh;
    float: left;

    .ol-zoom {
        position: absolute;
        background-color: rgba(255,255,255,0.4);
        border-radius: 4px;
        padding: 2px;
        top: 1em;
        right: 1em;
    }

    .ol-control button {
        display: flex;
        margin: 1px;
        font-size: 12px;
        font-weight: bold;
        justify-content: center;
        text-align: center;
        height: 16px;
        width: 16px;
        border: none;
        color: black;
        background-color: white;
    }

    .ol-zoom-in {
        border-radius: 2px 2px 0px 0px;
    }

    .ol-zoom-out {
        border-radius: 0px 0px 2px 2px;
    }

    .ol-rotate {
        display: none;
    }

    .ol-attribution {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        position: absolute;

        font-size: 10px;
        background-color: #ffffffcc;
        border-radius: 4px;
        padding: 2px 0px;
        bottom: 1em;
        right: 1em;
    }

    .ol-attribution button {
        display: flex;
        align-items: center;
        border-radius: 2px;
        background-color: #ffffff;
        cursor: pointer;
    }

    .ol-attribution ul {
        padding: 0px 5px;
    }

    .ol-attribution ul a {
        text-decoration: none;
        color: #000000;
    }

    .ol-attribution button[aria-expanded="false"] + ul {
        display: none;
    }

    .ol-popup {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        background-color: white;
        box-shadow: 0 1px 4px rgba(0,0,0,0.2);
        border-radius: 10px;
        border: 1px solid #cccccc;
        bottom: 12px;
        left: -50px;
        width: 170px;
        height: 60px;
    }
    .ol-popup:after, .ol-popup:before {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    .ol-popup:after {
        border-top-color: white;
        border-width: 10px;
        left: 48px;
        margin-left: -10px;
    }
    .ol-popup:before {
        border-top-color: #cccccc;
        border-width: 11px;
        left: 48px;
        margin-left: -11px;
    }
    .ol-popup-closer {
        text-decoration: none;
        position: absolute;
        top: 2px;
        right: 8px;
        cursor: pointer;
    }
    .ol-popup-closer:after {
        content: "✖";
    }

    .ol-popup #popup-content {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .ol-popup #name {
        font-size: 13px;
        margin-bottom: 5px;
    }

    .ol-popup #content {
        font-size: 14px;
        font-weight: bold;
    }
`

export const mapPost = css`
    position: absolute;
    z-index: 1;
    width: 690px;
    bottom: 1%;
`

export const legend = css`
    display: flex;
    float: right;
    vertical-align: middle;
    align-items: "end";
`
