/** @jsxImportSource @emotion/react */
import { PiPaperclip } from 'react-icons/pi';
import * as s from './style'
import React, { useRef } from 'react';
import FileIcon from '../../atoms/file_icon/FileIcon';
import { IoMdClose } from 'react-icons/io';

const FileUploadComponent = ({ fileList, setFileList }) => {
    const inputRef = useRef();

    const onChangeInputHandle = (e) => {
        const selectedFiles = e.target.files;
        if (selectedFiles.length > 0) {
            const files = [...fileList];
            for (let i = 0; i < selectedFiles.length; i++) {
                files.push(selectedFiles[i]);
            }
            setFileList(files);
        }
    };

    const onClickHeaderHandle = () => {
        if(inputRef.current) {
            inputRef.current.click();
        }
    }

    const onClickDeleteHandle = (index) => {
        const newFileList = [...fileList];
        newFileList.splice(index, 1);
        setFileList(newFileList);
    }

    const onDropHandle = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const newFiles = [...fileList];
            for (let i = 0; i < files.length; i++) {
                newFiles.push(files[i]);
            }
            setFileList(newFiles);
        }
    }

    const onDragOverHandle = (event) => {
        event.preventDefault();
    }

    return (
        <div css={s.mainContainer}>
            <header css={s.header}  onClick={onClickHeaderHandle} onDrop={onDropHandle} onDragOver={onDragOverHandle}>
                <PiPaperclip />
                <span>이 곳을 선택해 파일을 추가하거나 파일을 드래그 하세요. </span>
                <input type='file' style={{ display: "none"}} onChange={onChangeInputHandle} multiple ref={inputRef} />
            </header>
            <ul css={s.fileList}>
                {fileList.map((file, idx) => (
                    <li key={idx} css={s.file}>
                        <span onClick={() => onClickDeleteHandle(idx)} css={s.deleteIcon}><IoMdClose /></span>
                        <FileIcon fileName={file.name}/>
                        <span css={s.fileName}>{file.name}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FileUploadComponent;