import { css } from "@emotion/react";

export const mainContainer = css`
    width: 480px;
    height: 26vh;
    font-size: 12px;
    overflow-y: scroll;
`

export const closeContainer = css`
    font-size: 15px;
    padding: 5px;
    text-align: right;
    font-weight: 400;
`

export const closeButton = css`
    font-weight: 400;
    cursor: pointer;
`

export const table = css`
    width: 100%;
    font-size: 11px;
    border: 1px solid gray;
`

export const thead = css`
    width: 100%;
    height: 30px;
    color: #fff;
    background-color: #999999;
`

export const tbody= css`
    font-weight: 400;
`

export const tr = (flag) => css`
    background-color: ${flag ? "#b9b9b95c" : ""};
    &:hover {
        background-color: ${!flag ? "#e8e8e8" : ""};
    }
`

export const thAndTd = css`
    height: 22px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #a2a2a2;
`

export const year = css`
    cursor: pointer;
`

export const tableText = (year, sortBy) => css`
    font-weight: ${year === sortBy ? 700 : 400};
`

export const rankText = css`
    color: #002755;
`