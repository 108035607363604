/** @jsxImportSource @emotion/react */
import * as s from './style'
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../../../components/board/atoms/sidebar/Sidebar';
import axios from 'axios';
import { API_URL } from '../../../config/URL/URL';
import dayjs from 'dayjs';

import { GoPersonFill } from "react-icons/go";
import { IoIosTime } from "react-icons/io";
import { MdRemoveRedEye } from "react-icons/md";
import { MdArrowDropUp } from "react-icons/md";

import PostInfoContent from '../../../components/board/view_post/post_info/PostInfoContent';
import AttachComponent from '../../../components/board/view_post/attach/AttachComponent';
import CommentComponent from '../../../components/board/view_post/comment/CommentComponent';
import CommentInput from '../../../components/board/view_post/attach/comment_input/CommentInput';
import Button from '../../../components/board/atoms/button/Button';

const ViewPost = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [ data, setData ] = useState();
    const [ selectedBoard, setSelectedBoard ] = useState();
    const contentRef = useRef();


    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        if(data) {
            fetchContent();
        }
    }, [data])

    const fetchContent = () => {
        contentRef.current.innerHTML = data.content;
    }

    const fetchData = async () => {
        const postData = await getData();
        setData(postData);
    }

    const getData = async () => {
        const id = parseInt(params.postIndex);
        const option = {
            params: {
                postId: id
            }
        }

        try {
            const response = await axios.get(`${API_URL}/board/getpost`, option);
            return response.data;
        } catch(error) {

        }   
    }

    const onClickPostButtonHandle = () => {
        navigate(`/test/board/writepost/${selectedBoard.id}`);
    }

    const onClickToListHandle = () => {
        navigate(`/test/board/${selectedBoard.id}`);
    }

    const onClickToTopHandle = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    if(data)
    return (
        <div css={s.mainContainer}>
            <div css={s.sidebarContainer}>
                <Sidebar selectedBoard={selectedBoard} setSelectedBoard={setSelectedBoard} />
            </div>
            <div css={s.postContainer}>
                <header css={s.header}>
                    {selectedBoard ? selectedBoard.name : ""}
                </header>
                <div css={s.main}>
                    <div css={s.infoContainer}>
                        <h3 css={s.title}>{data.title}</h3>
                        <div css={s.postInfo}>
                            <PostInfoContent icon={<GoPersonFill />}>{data.author}</PostInfoContent>
                            <PostInfoContent icon={<IoIosTime />}>{dayjs(data.registeDate).format('YYYY-MM-DD HH:mm')}</PostInfoContent>
                            <PostInfoContent icon={<MdRemoveRedEye />}>{data.view}</PostInfoContent>
                        </div>
                    </div>
                    <div css={s.content} ref={contentRef}></div>
                </div>
                <div css={s.attachContainer}>
                    <AttachComponent data={data} />
                </div>
                <div css={s.commentContainer}>
                    {data.comment.map((comment) => (
                        <CommentComponent key={comment.id} data={comment}/>
                    ))}
                    <CommentInput postId={data.id}/>
                </div>
                <footer>
                    <div css={s.footerButtonContainer}>
                        <Button theme={"green"} onClick={onClickPostButtonHandle}>글쓰기</Button>
                        <div css={{ marginLeft: 'auto'}}>
                            <Button theme={"gray"} onClick={onClickToListHandle}>목록</Button>
                            <Button theme={"gray"} style={{ marginLeft: '10px'}} onClick={onClickToTopHandle}><MdArrowDropUp />TOP</Button>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default ViewPost;