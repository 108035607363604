import { css } from "@emotion/react"

export const mainContainer = css`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px 0px;
`

export const boardContainer = css`
    display: flex;
    flex-direction: column;
    width: 1100px;
    padding: 10px 10px 10px 20px;
`

export const header = css`
    display: flex;
    position: relative;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 45px;
    font-size: 24px;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #dbdbdb;
`

export const postContainer = css`
    padding-bottom: 10px;
`

export const post = css`
    display: flex;
    flex-direction: row;
    padding: 25px 15px;
    border-bottom: 1px solid #EAECF0;
    height: 200px;

    cursor: pointer;

    &:hover {
        background-color: #F2F4F7;
    }

    box-sizing: border-box;
`

export const lastPage = css`
    display: flex;
    flex-direction: row;
    padding: 25px 15px;
    border-bottom: 1px solid #EAECF0;
    height: 100px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
`

export const imgBox = css`
    display: flex;
    box-sizing: border-box;
    width: 160px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
`

export const thumb = css`
    width: 160px;
    /* height: 160px; */
    object-fit: cover;
`

export const contentBox = css`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 5px 20px;
    justify-content: space-between;
    box-sizing: border-box;
`

export const contentHeader = css`
    font-size: 18px;
    font-weight: 700;
`

export const contentMain = css`
    display: -webkit-box;
    color: #232324;
    font-size: 16px;
    line-height: 1.625;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
`

export const contentFooter = css`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #a6a7a9;
`

export const author = css`
    color: #232324;
    font-weight: 700;
    padding-right: 10px;
`

export const item = css`
    border-left: 1px solid #a6a7a9;
    padding: 0px 10px;
`

export const postButtonContainer = css`
    display: flex;
    padding-top: 30px;
    justify-content: flex-end;
`

export const postButton = css`
    display: flex;
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 100px;
    height: 40px;
    align-items: center;
    justify-content: center;
    background-color: #3671c8;
    border: none;
    color: #fff;
    cursor: pointer;
`