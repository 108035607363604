import { css } from '@emotion/react';

export const mainContainer = css`
    position: relative;
    width: 36%;
    height: 400px;
`

export const header = css`
    position: absolute;
    transform: translate(-55%, 30%);
    left: 50%;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 3px;
`
