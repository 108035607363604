/** @jsxImportSource @emotion/react */
import * as s from './style'
import { List, ListSubheader } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TopItem from './item/TopItem';
import Item from './item/Item';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from '../../../../config/URL/URL';

const Sidebar = ({ selectedBoard, setSelectedBoard }) => {
    const params = useParams();
    const navigate = useNavigate();
    
    const [ ungroupList, setUngroupList ] = useState([]);
    const [ groupList, setGroupList ] = useState([]);
    
    useEffect(() => {
        fetchList();
    }, [params])

    const fetchList = async () => {
        const data = await getList();
        const ungroups = data.filter((item) => item.type !== 0 && item.groupId === 0);
        const groups = data.filter((item) => item.type === 0);

        groups.forEach((group) => {
            group.element = data.filter((item) => item.groupId === group.groupId && item.type !== 0);
        })

        setSelectedBoard(data.find((item) => item.id === parseInt(params.boardIndex)));
        setUngroupList(ungroups);
        setGroupList(groups);
    }

    const getList = async () => {
        try {
            const response = await axios.get(`${API_URL}/board/metadata`);
            return response.data;

        } catch(error) {
            console.log(error);
        }
    }
    
    const onClickItemHandle = (item) => {
        navigate(`/test/board/${item.id}`);
    }

    return (
        <List
            sx={{ width: '100%', bgcolor: 'background.paper'}} 
            component="nav" 
            aria-labelledby='subheader' 
            subheader={
                <ListSubheader component="div" id='subheader'>
                    게시판 목록
                </ListSubheader>
        }>
            {ungroupList.map((item) => (
                <Item key={item.id} name={item.name} iconSrc={item.iconSrc} selected={selectedBoard ? selectedBoard.id === item.id : false} onClick={() => onClickItemHandle(item)}/>
            ))}
            
            {groupList.map((group) => (
                <TopItem key={group.id} name={group.name} iconSrc={group.iconSrc}>
                    {group.element.map((item) => (
                        <Item key={item.id} name={item.name} iconSrc={item.iconSrc} selected={selectedBoard ? selectedBoard.id === item.id : false} onClick={() => onClickItemHandle(item)} padding={4}/>
                    ))}
                </TopItem>
            ))}
        </List>
    );
};

export default Sidebar;