/** @jsxImportSource @emotion/react */
import * as s from './style'
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_URL } from '../../../config/URL/URL';
import SelectMemuItem from '../../atoms/table_element/SelectMemuItem';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { logTokenAtom } from '../../../atoms/log/LogTokenStore';
import { createLog } from '../../../atoms/log/LogFunction';

const CategoryMenu = ({ selectedCategory, setSelectedCategory }) => {
  const location = useLocation();
  const [ logTokenState, setLogTokenState ] = useRecoilState(logTokenAtom);
  
  const [ categoryData, setCategoryData ] = useState();
  const [ selectedCategoryGroup, setSelectedCategoryGroup ] = useState();

  useEffect(() => {
    fetchInit();
  }, []);

  const fetchInit = async () => {
    try {
      const resultArray = await getCategoryData();
      setCategoryData(resultArray);

      let initCategoryKey;
      let initGroupKey;
      let initCategoryGroup;
      let initCategory;

      if(location.state) {
        initGroupKey = location.state.categoryCd.slice(0,1);
        initCategoryKey = location.state.categoryCd;
      } else {
        initGroupKey = resultArray[0].categoryCd;
        initCategoryKey = resultArray[0].categories[0].categoryCd;
      }
      
      initCategoryGroup = resultArray.find(categoryGroup => categoryGroup.categoryCd === initGroupKey);
      initCategory = initCategoryGroup.categories.find(category => category.categoryCd === initCategoryKey);

      setSelectedCategoryGroup(initCategoryGroup);
      setSelectedCategory(initCategory);

    } catch (error) {
      console.error(error);
    }
  };

  const getCategoryData = async () => {
    const response = await axios.get(`${API_URL}/sodamap/category`);
    response.data.sort((a, b) => a.nm_kor.localeCompare(b.nm_kor));

    const resultArray = response.data.filter(item => item.category === "main");

    resultArray.forEach((group) => {
      const groupItem = response.data.filter(item => item.categoryCd[0] === group.categoryCd && item.category !== "main");
      group.categories = groupItem;
    })


    return resultArray;
  }

  const onClickCategoryGroupHandle = async (categoryGroup) => {
    setSelectedCategoryGroup(categoryGroup);

    const logOption = {
      page: location.pathname,
      eventName: "select_group",
      eventValue: categoryGroup.categoryCd,
      sessionToken: logTokenState,
    };

    const newToken = await createLog(logOption);
    setLogTokenState(newToken);
  }

  const onClickCategoryHandle = async (category) => {
    setSelectedCategory(category);

    const logOption = {
      page: location.pathname,
      eventName: "select_category",
      eventValue: category.categoryCd,
      sessionToken: logTokenState,
    };

    const newToken = await createLog(logOption);
    setLogTokenState(newToken);
  }
    
  if(categoryData)
  return (
      <div css={s.mainContainer}>
        <div css={s.col}>
          <header css={s.header}>분류</header>
          <div css={s.content}>
            {categoryData.map((categoryGroup, idx) =>
              <SelectMemuItem 
                key={idx}
                isSelected={categoryGroup.categoryCd === selectedCategoryGroup.categoryCd}
                onClick={() => onClickCategoryGroupHandle(categoryGroup)}>
                {categoryGroup.nm_kor}
              </SelectMemuItem>
            )}
          </div>
        </div>

        <div css={s.col}>
          <header css={s.header}>분류별 주제</header>
          <div css={s.content}>
            {selectedCategoryGroup.categories.map((category, idx) => 
              <SelectMemuItem 
                key={idx}
                isSelected={category.categoryCd === selectedCategory.categoryCd}
                onClick={() => onClickCategoryHandle(category)}>
                {category.nm_kor}
              </SelectMemuItem>
            )}
          </div>

        </div>
        <div css={s.col}>
          <header css={s.header}>Tip</header>
          <div css={s.content}>
            <span css={s.tipText}>{selectedCategory.tip}</span>
          </div>
        </div>
      </div>
  );
};

export default CategoryMenu;