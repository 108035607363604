/** @jsxImportSource @emotion/react */
import * as s from "./style"
import { IoClose } from "react-icons/io5";

const Popup = ({ popupData, selectedCategory, onClickCloseButton }) => {
    return (
        <div id="popup" css={s.mainContainer}>
            <div css={s.top}/>
            <p css={s.closer} onClick={onClickCloseButton}><IoClose /></p>
            { selectedCategory && popupData &&
                <div css={s.content}>
                    <header css={s.header}>
                        <p>{selectedCategory.nm_kor}</p>
                    </header>
                    <div css={s.textContainer}>
                        <p css={s.name}>{popupData.adminNm}</p>
                        <p css={s.text}>{popupData.val === "" ? "데이터 없음" : `${popupData.val.toLocaleString()}${selectedCategory.unit === "-" ? "" : selectedCategory.unit}`}</p>
                    </div>
                </div>
            }
        </div>
    );
};

export default Popup;