/** @jsxImportSource @emotion/react */
import axios from 'axios';
import * as s from './style'
import React, { useEffect, useState } from 'react';
import { API_URL } from '../../../config/URL/URL';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { logTokenAtom } from '../../../atoms/log/LogTokenStore';
import { createLog } from '../../../atoms/log/LogFunction';

const SidoList = ({ setSelectedSido, selectedYear, selectedCategory }) => {
    const location = useLocation();
    const [ logTokenState, setLogTokenState ] = useRecoilState(logTokenAtom);

    const [ list, setList ] = useState();
    
    useEffect(() => {
        fetchList();
    }, [])
    
    const fetchList = async () => {
        const data = await getSidoList();
        setList(data)
    }

    const getSidoList = async () => {
        try{
            const response = await axios.get(`${API_URL}/sodamap/sido_list`);
            
            const data = response.data;
            data.sort((a, b) => parseInt(a.sidoCd) - parseInt(b.sidoCd));

            return data;
        } catch(error) {
            console.log(error)
        }
    }

    const onChangeSelectHandle = async (e) => {
        const selectedValue = e.target.value;
        const sido = list.find(item => item.sidoNm === selectedValue)
        setSelectedSido(sido);

        const logOption = {
            page: location.pathname,
            eventName: "select_sido",
            eventValue: null,
            sessionToken: logTokenState,
            properties: {
                year: selectedYear,
                categoryCd: selectedCategory.categoryCd,
                sidoCd: sido.sidoCd,
                sidoNm: sido.sidoNm,
            }
          };
      
          const newToken = await createLog(logOption);
          setLogTokenState(newToken);
    }

    if(list)
    return (
        <select css={s.mainContainer} onChange={onChangeSelectHandle}>
            <option css={s.option}>시도 선택</option>
            {list.map((sido) => (
                <option css={s.option} key={sido.gid}>{sido.sidoNm}</option>
            ))}
        </select>
    );
};

export default SidoList;