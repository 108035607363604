/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';

const buttonContainer = css`
    background-color: transparent;
    border: none;
    cursor: pointer;
`;

const ImgToggleButton = ({ path, isToggled, hover, firstImgSource, secondImgSource, onClick }) => {
    const [ imgPath, setImgPath ] = useState(firstImgSource);

    useEffect(() => {
        if(isToggled) {
            setImgPath(firstImgSource);
        } else {
            setImgPath(secondImgSource);
        }
    }, [isToggled])

    const onMouseOverButtonHandle = () => {
        if(hover && isToggled) {
            setImgPath(secondImgSource);
        }
    }

    const onMouseLeaveButtonHandle = () => {
        if(hover && isToggled) {
            setImgPath(firstImgSource)
        }
    }

    return (
        <button css={buttonContainer} onClick={onClick} onMouseOver={onMouseOverButtonHandle} onMouseLeave={onMouseLeaveButtonHandle}>
            <img src={imgPath} alt={path}/>
        </button>
    );
};

export default ImgToggleButton;