/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

const mainContainer = css`
    display: flex;
    font-size: 12px;
    color: #666666;
    text-align: center;
    padding: 2px 4px;
`

const text = css`
    margin-left: 3px;
`

const PostInfoContent = ({ icon, children }) => {
    return (
        <div css={mainContainer}>
            {icon}
            <span css={text}>{children}</span>
        </div>
    );
};

export default PostInfoContent;