import { css } from "@emotion/react";

export const mainContainer = css`
    display: flex;
    width: 750px;
    flex-direction: row;
`

export const mapArea = css`
    display: flex;
    width: 700px;
    /* width: 100%; */
    height: 95vh;
    float: left;

    .ol-zoom {
        position: absolute;
        background-color: rgba(255,255,255,0.4);
        border-radius: 4px;
        padding: 2px;
        top: 1em;
        right: 1em;
    }

    .ol-control button {
        display: flex;
        margin: 1px;
        font-size: 12px;
        font-weight: bold;
        justify-content: center;
        text-align: center;
        height: 16px;
        width: 16px;
        border: none;
        color: black;
        background-color: white;
    }

    .ol-zoom-in {
        border-radius: 2px 2px 0px 0px;
    }

    .ol-zoom-out {
        border-radius: 0px 0px 2px 2px;
    }

    .ol-rotate {
        display: none;
    }

    .ol-attribution {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        position: absolute;

        font-size: 10px;
        background-color: #ffffffcc;
        border-radius: 4px;
        padding: 2px 0px;
        bottom: 1em;
        right: 1em;
    }

    .ol-attribution button {
        display: flex;
        align-items: center;
        border-radius: 2px;
        background-color: #ffffff;
        cursor: pointer;
    }

    .ol-attribution ul {
        padding: 0px 5px;
    }

    .ol-attribution ul a {
        text-decoration: none;
        color: #000000;
    }

    .ol-attribution button[aria-expanded="false"] + ul {
        display: none;
    }
    
`

export const mapPost = css`
    position: absolute;
    z-index: 1;
    width: 690px;
    bottom: 1%;
`

export const legend = css`
    display: flex;
    float: right;
    vertical-align: middle;
    align-items: "end";
`
