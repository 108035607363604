/** @jsxImportSource @emotion/react */
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts'
import * as s from './style'
import React, { useEffect, useState } from 'react';

const SummaryGraph = ({ data, selectedCategory, selectedSgg }) => {
    const [ options, setOptions ] = useState({
        title: { text: "" },
        chart: {
          backgroundColor: "rgba(0, 0, 0, 0)",
          height: 220,
          type: "column",
          style: { fontFamily: "SCDream4" },
        },
        xAxis: {
          labels: { style: { fontSize: "14px", fontWeight: 400, color: "#002755" } },
        },
        yAxis: { labels: { enabled: false }, title: { text: "" } },
        legend: { itemStyle: { fontSize: "13px", fontWeight: 700 } },
        credits: { enabled: false },
        series: [],
        accessibility: { enabled: false }
    });

    useEffect(() => {
        if(selectedCategory) {
            Highcharts.setOptions({ lang : {thousandsSep:','} });
            const tooltip = {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}:&nbsp </td>' +
                '<td style="padding:0"><b>{point.y:,f} ' + selectedCategory.unit + '</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            }

            setOptions((prevOptions) => ({
                ...prevOptions,
                tooltip: tooltip
            }))
        }
    }, [selectedCategory])


    useEffect(() => {
        setAllSggData();
        Highcharts.setOptions({ lang : {thousandsSep:','} });
    }, [data])

    useEffect(() => {
        const allSggSeries = options.series.find(item => item.name === "전국");
        setSelectedSggData(allSggSeries);     
    }, [selectedSgg])

    const setAllSggData = () => {
        if(data && selectedCategory) {
            const allSggData = getAllSggData();
            const yearArray = Array.from({ length: allSggData.length}, (_, index) => (parseInt(selectedCategory.start_yyyy) + index));
            
            const allSggDataArray = yearArray.map((year) => {
                const result =  {
                    x: year,
                    y: allSggData.find(item => item.year === year.toString()).val
                }
                
                return result;
            })

            const allSggSeries = {
                name: "전국",
                color : "#002755",
                data: allSggDataArray
            }          

            if(selectedSgg.adminCd === "") {
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    series: [allSggSeries]
                }))
            } else if(selectedSgg.adminCd !== "") {
                setSelectedSggData(allSggSeries)
            }
        }
    }

    const setSelectedSggData = (allSggSeries) => {
        if(data && selectedCategory) {
            const selectedSggData = getSelectedSggData();
            const yearArray = Array.from({ length: selectedSggData.length}, (_, index) => (parseInt(selectedCategory.start_yyyy) + index));
    
            const selectedSggDataArray = yearArray.map((year) => {
                const result =  {
                    x: year,
                    y: selectedSggData.find(item => item.year === year.toString()).val
                }
                
                return result;
            })
    
            const selectedSggSeries = {
                name: selectedSgg.adminNm,
                color : "#ff9112",
                data: selectedSggDataArray
            }
            


            if(selectedSgg.adminCd === "") {
                setAllSggData();
            } else if(allSggSeries) {
                setSeries([allSggSeries, selectedSggSeries]);
            }
        }
    }

    const getAllSggData = () => {
        const resultArray = data.filter(item => item.adminCd.trim() === "0");
        return resultArray;
    }

    const getSelectedSggData = () => {
        const resultArray = data.filter(item => item.adminCd.trim() === selectedSgg.adminCd.trim());
        return resultArray;
    }

    const setSeries = (data) => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            series: data
        }))
    }

    if(options)
    return (
        <div css={s.mainContainer}>
            <HighchartsReact highcharts={Highcharts} options={options}/>
        </div>
    );
};

export default SummaryGraph;