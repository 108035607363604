/** @jsxImportSource @emotion/react */
import * as s from './style'
import React, { useEffect, useState } from 'react';
import Table from './table/Table';
import PageComponent from '../atoms/pagination/PageComponent';
import SearchBar from '../atoms/search_bar/SearchBar'
import { useNavigate } from 'react-router-dom';
import { getPageSize, getPosts } from '../function/BoardFunction';

const GeneralBoard = ({ selectedBoard, searchOption }) => {
    const navigate = useNavigate();
    const { id: boardId, count, name } = selectedBoard;
    
    const [ postArray, setPostArray ] = useState();
    const [ pageSize, setPageSize ] = useState();
    const [ selectedPage, setSelectedPage ] = useState(1);

    useEffect(() => {
        fetchData(boardId, 1, searchOption, count);

        return () => {
            setSelectedPage(1);
            setPostArray([]);
        }

    }, [selectedBoard])

    useEffect(() => {
        fetchData(boardId, selectedPage, searchOption, count);
    }, [selectedPage])

    const fetchData = async (boardId, page, searchOption, count) => {
        const data = await getPosts(boardId, page, searchOption, count);
        const sortedData = data.sort((a, b) => b.number - a.number);

        const size = await getPageSize(boardId, searchOption, count);
        
        setPageSize(size);
        setPostArray(sortedData);
    }

    const onClickPostButtonHandle = () => {
        navigate(`/test/board/writepost/${selectedBoard.id}`)
    }

    return (
        <div css={s.mainContainer}>
            <div css={s.boardContainer}>
                <header css={s.header}>
                    {name}
                </header>
                <div css={s.topMenu}>
                    <div css={s.topMenuItem}></div>
                    <div css={s.topMenuItem}></div>
                </div>
                <SearchBar selectedBoard={selectedBoard} searchOption={searchOption} />
                <Table data={postArray} selectedBoard={selectedBoard}/>
                <PageComponent pageSize={pageSize} selectedPage={selectedPage} setSelectedPage={setSelectedPage}/>
                <div css={s.postButtonContainer}>
                    <button css={s.postButton} onClick={onClickPostButtonHandle}>글쓰기</button>
                </div>
            </div>
        </div>
    );
};

export default GeneralBoard;