import { css } from "@emotion/react";

export const mainContainer = css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: white;
    box-shadow: 10px 10px 4px rgba(0,0,0,0.4);
    border-radius: 3px;
    border: 1px solid #cccccc;
    bottom: 12px;
    min-width: 160px;
    transform: translateX(-50%);

    &::after, &::before {
        top: 100%;
        border: solid transparent;
        content: " ";
        left: 90px;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &::after {
        border-top-color: white;
        border-width: 10px;
        margin-left: -10px;
    }

    &::before {
        border-top-color: #cccccc;
        border-width: 11px;
        margin-left: -11px;
    }
`

export const top = css`

`

export const closer = css`
    font-size: 12px;
    text-decoration: none;
    position: absolute;
    top: 3px;
    right: 7px;
    cursor: pointer;
`

export const content = css`
    display: flex;
    width: 100%;
    align-self: flex-start;
    justify-content: flex-start;
    padding: 10px 10px 20px 10px;
    flex-direction: column;
    white-space: nowrap;
`

export const header = css`
    font-size: 16px;
    font-weight: bold;
    padding-right: 10px;
    padding-bottom: 10px;
    color: #002755;
`

export const name = css`
    font-size: 13px;
    padding-bottom: 5px;
`

export const text = css`
    font-size: 12px;
`
