/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { API_URL } from '../../../../../config/URL/URL';
import axios from 'axios';

const mainContainer = css`
    display: flex;
    flex-direction: column;
    border: 2px solid #dbdbdb;
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;
`

const textArea = css`
    width: 100%;
    resize: none;
    border: none;

    &:focus {
        outline: none;
    }
`

const button = css`
    font-weight: 700;
    border: none;
    border-radius: 6px;
    background-color: rgba(3, 199, 90, 0.12);
    color: #009f47;
    padding: 10px;
    align-self: flex-end;
    cursor: pointer;
`

const grayButton = css`
    font-weight: 700;
    border: none;
    border-radius: 6px;
    background-color: #eff0f2;
    color: #000000;
    padding: 10px;
    align-self: flex-end;
`

const CommentInput = ({ postId }) => {
    const [ content, setContent ] = useState("");
    const [ buttonActive, setButtonActive ] = useState(false);

    useEffect(() => {
        if(content.length > 0) {
            setButtonActive(true);
        } else {
            setButtonActive(false);
        }
    }, [content])
        
    const saveComment = async () => {
        const data = {
            content: content,
            author: "test",
            postId: postId
        }

        const option = {
            headers: {
                "Content-Type": "application/json"
            }
        }

        try {
            await axios.post(`${API_URL}/board/post/comment/save`, JSON.stringify(data), option);
            window.location.reload();
        } catch(error) {
            console.log(error);
        }
    }

    const onChangeTextHandle = (e) => {
        setContent(e.target.value)
    }

    const onClickSaveButtonHandle = () => {
        saveComment();
    }
    
    return (
        <div css={mainContainer}>
            <textarea css={textArea} type="text" placeholder='댓글을 남겨보세요' onChange={onChangeTextHandle} ></textarea>
            { buttonActive 
                ? <button css={button} onClick={onClickSaveButtonHandle}>등록</button>
                : <button css={grayButton} >등록</button>
            }
            
        </div>
    );
};

export default CommentInput;