/** @jsxImportSource @emotion/react */
import * as s from './style' 
import React, { useEffect, useState } from 'react';
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const PageComponent = ({ pageSize, selectedPage, setSelectedPage }) => {
    const [ pages, setPages ] = useState([]);

    useEffect(() => {
        if(pageSize) {
            fetchPages();
        }
    }, [selectedPage, pageSize])

    const fetchPages = () => {
        let firstPage;
        let pageArray;
        
        if(selectedPage <= 5) {
            firstPage = 1;
        } else if (pageSize - selectedPage <= 5) {
            firstPage = pageSize - 9;
        } else {
            firstPage = selectedPage - 4;
        }
        
        if(pageSize <= 10) {
            pageArray = Array.from({length: pageSize}, (_, index) => index + 1);
        } else {
            pageArray = Array.from({length: 10}, (_, index) => firstPage + index);
        }

        setPages(pageArray);
    }

    const onClickPageHandle = (page) => {
        setSelectedPage(page);
    }

    const onClickfirstPageHandle = () => {
        setSelectedPage(1);
    }

    const onClickLastPageHandle = () => {
        setSelectedPage(pageSize);
    }

    const onClickPrevPageHandle = () => {
        if(selectedPage !== 1) {
            setSelectedPage(selectedPage - 1);
        }
    }

    const onClickNextPageHandle = () => {
        if(selectedPage !== pageSize) {
            setSelectedPage(selectedPage + 1)
        }
    }

    return (
        <div css={s.mainContainer}>
            <ul css={s.pageContainer}>
                <li css={s.movePageButton} onClick={onClickfirstPageHandle}><MdKeyboardDoubleArrowLeft /></li>
                <li css={s.movePageButton} onClick={onClickPrevPageHandle}><MdOutlineKeyboardArrowLeft /></li>
                {pages.map((page) => (
                    <li key={page} css={selectedPage === page ? s.selectedPage : s.page} onClick={() => onClickPageHandle(page)}>{page}</li>
                ))}
                <li css={s.movePageButton} onClick={onClickNextPageHandle}><MdOutlineKeyboardArrowRight /></li>
                <li css={s.movePageButton} onClick={onClickLastPageHandle}><MdKeyboardDoubleArrowRight /></li>
            </ul>
        </div>
    );
};

export default PageComponent;