import { css } from "@emotion/react";

export const mainContainer = css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 10px;
`

export const pageContainer = css`
    display: flex;
    flex-direction: row;
`

export const page = css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: #337ab7;
    border: 1px solid #dbdbdb;
    background-color: #FFFFFF;
    margin: 0px -1px;
    cursor: pointer;
`

export const selectedPage = css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: #FFFFFF;
    border: 1px solid #337ab7;
    background-color: #337ab7;
    cursor: pointer;
    z-index: 1;
`

export const movePageButton = css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    font-size: 17px;
    color: #337ab7;
    border: 1px solid #dbdbdb;
    background-color: #FFFFFF;
    margin: 0px -1px;
    cursor: pointer;
`