import { css } from "@emotion/react";

export const mainContainer = css`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px 0px;
`

export const sidebarContainer = css`
    display: flex;
    width: 250px;
    min-height: 720px;
    flex-direction: row;
    
    border: 1px solid #dbdbdb;
`

export const boardContainer = css`
    display: flex;
    background-color: #fff;
    width: 1100px;
    
    border: 1px solid #dbdbdb;
    margin-left: -1px;
`
