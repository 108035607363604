/** @jsxImportSource @emotion/react */
import ToggleableImg from '../atoms/button/ToggleableImg';
import * as s from './style'
import React from 'react';

const Footer = ({ isRoot }) => {
    return (
        <div css={s.container(isRoot)}>
            <div css={s.logo}>
                <ToggleableImg isToggle={isRoot} firstImgSource={"/images/yp_logo_1.png"} secondImgSource={"/images/yp_logo_2.png"}/>
                <div css={s.line}></div>
                <ToggleableImg isToggle={isRoot} firstImgSource={"/images/plq-logo1.png"} secondImgSource={"/images/plq-logo2.png"}/>
            </div>
            <div css={s.copyright(isRoot)}>
                Ypartners Corp. & PLQ Partners Inc.<br />
                주식회사 와이파트너즈 & 피엘큐파트너스 주식회사<br />
                경상남도 진주시 충의로 24, 5F | 서울특별시 강남구 테헤란로5길 7, 9F<br />
                Copyright 2021. Ypartners Corp. & PLQ Partners Inc. All Rights Reserved
            </div>
        </div>
    );
};

export default Footer;